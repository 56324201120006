const supportCenterRoutes = [
  {
    path: "/support-center",
    name: "supportCenter",
    component: () => import("@/views/supportCenter/index.vue"),
    meta: {
      title: "帮助中心"
    }
  },
  {
    path: "/support-center-pre",
    name: "supportCenterPreview",
    component: () => import("@/views/supportCenter/preview.vue"),
    meta: {
      title: "帮助中心"
    }
  },
  {
    path: "/compose-export-view",
    name: "composeExportView",
    component: () => import("@/views/smartCompose/exportView.vue"),
    meta: {
      title: "查看驳回通知分析书"
    }
  },
  {
    path: "/apply-invoice-ex",
    name: "applyInvoiceEX",
    component: () => import("@/views/workCenter/applyInvoice/applyInvoice.vue"),
    meta: {
      title: "发票申请"
    }
  },
  {
    path: "/invoice-submit-ex",
    name: "invoiceSubmitEX",
    component: () => import("@/views/workCenter/applyInvoice/submit.vue"),
    meta: {
      title: "发票申请"
    }
  },
  {
    path: "/mail-address-ex",
    name: "mailAddressEX",
    component: () => import("@/views/workCenter/mailAddress/index.vue"),
    meta: {
      title: "邮寄地址管理"
    }
  },
  {
    path: "/customer-clue/:type",
    name: "customerClue",
    component: () => import("@/views/toolList/customerClue/index.vue"),
    meta: {
      title: "客户线索"
    }
  }
];
const wxOuterPageRoutes = [
  {
    path: "/wx-share-brand-assess",
    name: "wx_shareBrandAssess",
    component: () => import("@/views/brandAssess/h5_share.vue"),
    meta: {
      title: "商标价格咨询报告",
      noSide: true
    }
  },
  {
    path: "/wx-patent-detail-info",
    name: "wx_patentDetailInfo",
    component: () =>
      import(
        "@/views/workCenter/brandMonitor/patentMonitor/wx_patentDetailInfo.vue"
      ),
    meta: {
      title: "专利监测缴费详情",
      noSide: true
    }
  },
  {
    path: "/wx-risk-report-info",
    name: "wx_riskReportInfo",
    component: () =>
      import(
        "@/views/toolList/customerClueHome/components/riskReport/wx_riskReportInfo.vue"
      ),
    meta: {
      title: "风险报告详情详情",
      noSide: true
    }
  }
];

const newMonitorRoutes = [
  {
    path: "/new-brand-monitor",
    name: "newBrandMonitor",
    component: () =>
      import("@/views/workCenter/brandMonitor/newBrandMonitor/index.vue"),
    meta: {
      title: "新版客户监测"
    }
  }
];
const experienceGiftRoutes = [
  {
    path: "/experience-gift",
    name: "experienceGift",
    component: () =>
      import("@/views/starfishPlan/workBench/experienceGift.vue"),
    meta: {
      title: "体验大礼包"
    }
  }
];
const externalPageRoutes = [
  ...supportCenterRoutes,
  ...wxOuterPageRoutes,
  ...newMonitorRoutes,
  ...experienceGiftRoutes
];
export default externalPageRoutes;
