<template>
  <div id="hhr-leftNav">
    <ul class="nav-box globalScrool">
      <li class="menu" data-type="inside" v-if="isInside">
        <div class="menu-header" data-item="inside" @click="isOpen">
          <i :class="insideIcon ? 'icon' : 'icon-arrowdown'"></i>
          <span class="text">内部办公</span>
        </div>
        <ul class="menu-body" :class="insideIcon ? 'menu-body-hidden' : ''">
          <li
            v-for="(item, index) in insideList"
            :key="item.name"
            @click="navClick(item, index, 'insideList')"
            :class="item.isSelected ? 'selected' : ''"
          >
            <a class="text">{{ item.name }}</a>
          </li>
        </ul>
      </li>
      <li class="menu" data-type="server">
        <div class="menu-header" data-item="server" @click="isOpen">
          <i :class="serverIcon ? 'icon' : 'icon-arrowdown'"></i>
          <span class="text">工作中心</span>
        </div>
        <ul class="menu-body" :class="serverIcon ? 'menu-body-hidden' : ''">
          <li
            v-for="(item, index) in serverList"
            :key="item.name"
            @click="navClick(item, index, 'serverList')"
            :class="{
              selected: item.isSelected,
              'diy-pop-class': item.name.indexOf('流失提醒') != -1
            }"
          >
            <a class="text"
              >{{ item.name }}
              <span v-if="item.name == '流失提醒'">({{ customerLossCount }})</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="menu" data-type="tool">
        <div class="menu-header" data-item="tool" @click="isOpen">
          <i :class="toolIcon ? 'icon' : 'icon-arrowdown'"></i>
          <span class="text">常用工具</span>
        </div>
        <ul class="menu-body" :class="toolIcon ? 'menu-body-hidden' : ''">
          <li
            v-for="(item, index) in toolList"
            :key="item.name"
            @click="navClick(item, index, 'toolList')"
            :class="{
              'rel-tool': item.new,
              selected: item.isSelected,
              'patent-menu': item.name == '专利年费监测'
            }"
          >
            <a class="text">
              {{ item.name }}
              <i class="icon-new" :class="item.new ? '' : 'icon-new-hidden'"></i>
            </a>
          </li>
        </ul>
      </li>
      <li class="menu" data-type="news">
        <div class="menu-header" data-item="news" @click="isOpen">
          <i :class="newsIcon ? 'icon' : 'icon-arrowdown'"></i>
          <span class="text">知产课堂</span>
        </div>
        <ul class="menu-body" :class="newsIcon ? 'menu-body-hidden' : ''">
          <li
            v-for="(item, index) in newsList"
            :key="item.name"
            @click="navClick(item, index, 'newsList')"
          >
            <a class="text">{{ item.name }}</a>
          </li>
        </ul>
      </li>
    </ul>

    <!-- 广告气泡  -->
    <div
      class="diy-popper"
      :class="{ 'no-date': !customerLossCount }"
      v-if="!serverIcon"
      v-show="showAdvPop"
      @click="closeAdvPop(1)"
    >
      <i class="el-icon-circle-close" @click.stop="closeAdvPop(0)"></i>
      <p v-show="customerLossCount">{{ customerLossCount }}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { listMemberType } from "@/api/membership";
import { routeBaseUrl, insideList, toolList, serverList, newsList } from "@/utils/static-data";
import jsCookie from "js-cookie";

export default {
  name: "LeftAside",
  data() {
    return {
      // 左侧边栏是否展开二级目录
      insideIcon: true,
      serverIcon: false, // 商标注册页默认展开工作中心
      toolIcon: false,
      newsIcon: true,
      memberTypeList: [],
      memberTypeName: "",
      // 内部办公
      insideList: insideList,
      // 工作中心
      serverList: serverList,
      // 常用工具
      toolList: toolList,
      // 知产资讯
      newsList: newsList,
      showAdvPop: false
    };
  },
  computed: {
    ...mapGetters(["isInside", "membershipInfo", "permissionList", "customerLossCount"])
  },
  watch: {
    //当前路由路径 用于菜单切换
    "$store.state.nowRoute": {
      handler(n) {
        let allRouteList = [...this.insideList, ...this.serverList, ...this.toolList];
        allRouteList.forEach(e => {
          e.isSelected = false;
        });

        if (allRouteList.filter(i => i.hrefName == decodeURI(n)).length > 0) {
          allRouteList.filter(i => i.hrefName == decodeURI(n))[0].isSelected = true;
        }

        let allRouteArray = [this.insideList, this.serverList, this.toolList];
        allRouteArray.forEach(i => {
          if (i.find(j => j.hrefName == decodeURI(n))) {
            this.insideIcon = true;
            this.serverIcon = true;
            this.toolIcon = true;

            switch (i) {
              case this.insideList:
                this.insideIcon = false;
                break;
              case this.serverList:
                this.serverIcon = false;
                break;
              case this.toolList:
                this.toolIcon = false;
                break;
              default:
                break;
            }
          }
        });
      },
      immediate: true
    },
    memberTypeList() {
      this.memberTypeName =
        this.memberTypeList.find(e => e.id == this.membershipInfo.memberTypeId)?.name || "";
    }
  },
  created() {
    this.listMemberType();

    setTimeout(() => {
      this.initAdvPop();
    }, 500);
  },
  methods: {
    // 切换展开左侧边栏二级目录
    isOpen(e) {
      var ename = e.currentTarget.dataset.item + "Icon";
      this[ename] = !this[ename];

      setTimeout(() => {
        this.setPopperPosition(".diy-pop-class");
      }, 100);
    },
    navClick(item, i, cur) {
      //判断是非有埋点数据
      if (item.statistic) this.common.ipStatisticFn(item.statistic);

      const menuPermission = this.permissionList
        .filter(i =>
          ["商标起名", "客户管理", "商标搜索", "商标估价", "商标批量注册"].includes(i.dictName)
        )
        .find(i => i.dictName == item.name.slice(0, 4));
      if (item.hasPermission && !menuPermission) {
        this.$toast.showToast("权限暂未开放，请联系顾问咨询处理");
        return;
      }
      //判断线索权限->end
      if (cur == "insideList" || cur == "serverList") {
        let hasRouter = [
          "/brand-register",
          "/package-order",
          "/smart-order",
          "/apply-invoice",
          "/office-manage",
          "/export-record",
          "/mail-address",
          "/customer-manage",
          "/order-assistant",
          "/customer-loss"
        ];
        if (item.hrefName.includes("customer-loss")) {
          this.closeAdvPop();
        }
        if (hasRouter.includes(item.hrefName)) {
          this.$router.push(item.hrefName);
          let allRouteList = [...this.insideList, ...this.serverList];
          allRouteList.forEach(e => {
            e.isSelected = false;
          });
          item.isSelected = true;
        } else {
          window.location.href = routeBaseUrl + item.hrefName;
        }
      } else if (cur == "toolList") {
        // 判断线索权限
        const monitorPermission = this.permissionList.find(i => i.dictName == "客户监测");
        if (item.monitorPermission && !monitorPermission) {
          this.$toast.showToast("权限暂未开放，请联系顾问咨询处理");
          return;
        }
        //end->
        if (item.name == "商标交易查询" || item.name == "专利交易查询") {
          window.open(item.hrefName);
          return;
        }
        let hasRouter = ["/brand-monitor", "/brand-monitor-task", "/customer-clue-new"];
        if (item.hrefName == "/customer-clue-new") {
          window.open("/starfish/customer-clue-new");
          return;
        }

        hasRouter.includes(item.hrefName.split("?")[0])
          ? window.open(item.hrefName)
          : setTimeout(() => {
              window.location.href = item.hrefName;
            }, 300);
      } else {
        setTimeout(() => {
          window.location.href = item.hrefName;
        });
      }
    },
    async listMemberType() {
      let res = await listMemberType({});
      this.memberTypeList = res;
    },
    //初始化广告
    initAdvPop() {
      try {
        const flagValue = jsCookie.get("doNotShow");
        // 广告气泡的方法;
        if (!flagValue) {
          this.showAdvPop = true;
        }
        if (document.querySelector(".diy-pop-class")) {
          this.createTemplate(".diy-pop-class");
        }
        this.setPopperPosition(".diy-pop-class");
        document
          .querySelector(".globalScrool")
          .addEventListener("scroll", () => this.setPopperPosition(".diy-pop-class"));

        //页面改变再次广告定位
        window.addEventListener("resize", () => this.setPopperPosition(".diy-pop-class"));
      } catch (error) {
        console.log(error);
      }
    },
    //创建广告气泡的方法
    createTemplate(ele) {
      let divElement = document.querySelector(".diy-popper");
      // 设置popper元素的位置
      const referenceElement = document.querySelector(ele);
      // const rect = referenceElement.getBoundingClientRect();
      // divElement.style.top = rect.top - 30 + "px";
      // divElement.style.left = rect.left + 120 + "px";
    },
    //设置广告位置
    setPopperPosition(ele) {
      try {
        if (this.showAdvPop) {
          let divElement = document.querySelector(".diy-popper");
          const element = document.querySelector(ele);
          const rect = element && element.getBoundingClientRect();
          divElement.style.top = rect.top - 10 + "px";
          divElement.style.left = rect.left + 120 + "px";
        }
      } catch (error) {
        console.log(error);
      }
    },
    //关闭广告
    closeAdvPop(flag) {
      this.showAdvPop = false;
      // 获取今天的日期
      const today = new Date();
      today.setHours(24, 0, 0, 0); // 将时间设置为今天的午夜（24点）
      jsCookie.set("doNotShow", "1", { expires: today });
      if (flag) window.open("/customer-loss");
    }
  }
};
</script>
<style lang="scss" scoped>
#hhr-leftNav {
  width: 140px;
  height: auto;
  border: none;

  .nav-box {
    padding: 10px 0;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 80px);

    .menu {
      .menu-header {
        margin-top: 11px;
        margin-bottom: 11px;
        height: 26px;
        line-height: 26px;
        cursor: pointer;
        font-size: 16px;

        .icon {
          margin: 9px 8px 0 17px;
          width: 6px;
          height: 9px;
          background: url(../assets/images/icon-arrow-000-r.png) no-repeat 0 center;
          background-size: contain;
          display: block;
          float: left;
          border-radius: 50%;
        }

        .icon-arrowdown {
          width: 9px;
          height: 6px;
          background: url(../assets/images/icon-arrow-000-u.png) no-repeat 0 center;
          background-size: contain;
          display: block;
          float: left;
          margin: 10px 8px 0 15px;
          border-radius: 50%;
        }

        .text {
          font-weight: bold;
        }
      }

      .menu-body {
        cursor: pointer;
        li:hover {
          background-color: #fff4eb;
          a {
            color: #ff7200;
          }
        }
        li {
          a {
            padding-left: 32px;
            line-height: 36px;
            font-size: 13px;
            position: relative;
            color: #666;
          }
          .icon-new {
            position: absolute;
            right: -20px;
            top: -20px;
            vertical-align: 1px;
            display: inline-block;
            width: 25px;
            height: 25px;
            background: url(../assets/images/icon-new.gif) no-repeat center;
            background-size: cover;
          }
          .icon-new-hidden {
            display: none;
          }
        }

        li.selected {
          a {
            padding-left: 28px;
            border-left: 4px solid #ff7200;
            color: #ff7200;
          }
        }
        li.rel-tool {
          position: relative;
        }
      }
      .menu-body-hidden {
        display: none;
      }
    }

    .menu:after {
      content: "";
      display: block;
      margin-left: 20px;
      width: 100px;
      height: 1px;
      border-bottom: 1px solid #f7f7f8;
    }
  }
}

.vip-banner {
  cursor: pointer;
  background-image: url(~@/assets/images/membership/vip-bg.png);
  background-size: contain;
  height: 65px;
  border-radius: 5px 5px 0 0;
  padding: 10px 12px 10px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  .name {
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #653607;
  }
  .time {
    color: #5f2f00;
  }
}
.globalScrool::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}
.globalScrool::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #e4e4e4;
}
.globalScrool::-webkit-scrollbar-track {
  background: transparent;
}
</style>

<style lang="scss" >
.diy-popper {
  width: 193px;
  height: 101px;
  z-index: 9999;
  position: fixed;
  top: -500px;
  transition: top 0.1s linear, left 0.3s; /* 添加过渡效果 */
  background-image: url(~@/assets/images/customerLoss/pop-bg.png);
  background-size: 100% 100%;

  i {
    position: absolute;
    right: -18px;
    top: 42px;
    cursor: pointer;
    font-size: 18px;
    color: #666666;
  }
  p {
    position: absolute;
    top: 29px;
    left: 36px;
    font-size: 20px;
    font-weight: bold;
    color: #ff6655;
  }
}
.diy-popper.no-date {
  background-image: url(~@/assets/images/customerLoss/pop-bg2.png);
}
</style>
