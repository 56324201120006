<template>
  <div class="header-box">
    <div class="logo">
      <a :href="routeBaseUrl">
        <img :src="`${routeBaseUrl}/qdshome/images/icons/logo.png`" alt="商标注册-商标买卖-权大师"
      /></a>
    </div>
    <div class="header-nav">
      <template v-if="!$route.meta.headerBtn">
        <dl class="qds-dropdown" v-if="isInside">
          <dt class="drop-btn personal-main">
            <a class="text" :href="routeBaseUrl + '/agent/all-service/index'">内部办公</a>
          </dt>
        </dl>
        <dl class="qds-dropdown">
          <dt class="drop-btn personal-main" :class="{ active: nowRoutePath == '/partner-center' }">
            <a class="text" @click="goHomePage">合伙人中心</a>
          </dt>
        </dl>
        <dl class="qds-dropdown" v-show="isVIP">
          <dt class="drop-btn personal-main institution">
            <span class="text" @click="goInstitution">海星计划</span>
            <!-- <img src="@/assets/images/membership/vip-icon2.png" alt="" class="icon-vip" /> -->
          </dt>
        </dl>
        <dl class="qds-dropdown" v-if="isPatentService">
          <dt class="drop-btn personal-main">
            <a class="text" @click="openPath(routeBaseUrl + '/agent/patent-service/index')">
              专利案件撰稿
            </a>
          </dt>
        </dl>
      </template>
      <template v-if="$route.meta.headerBtn">
        <dl class="qds-dropdown">
          <dt
            class="drop-btn personal-main"
            :class="{ active: nowRoutePath == $route.meta.headerLink }"
          >
            <a class="text" @click="$router.push({ path: $route.meta.headerLink })">{{
              $route.meta.headerBtn
            }}</a>
          </dt>
        </dl>
      </template>

      <div class="header-box-pay-right" v-show="username">
        <div class="gift-invt" @click="teamInviteDialogVisible = true">
          <img src="@/assets/images/workBench/gift-icon.png" alt="" />
          <span>邀请有礼</span>
        </div>
        <div
          class="support-center"
          @mouseover="showMiniProgramModal = true"
          @mouseout="showMiniProgramModal = false"
          @click="starfishDialogVisible = true"
        >
          <img src="@/assets/images/starfish/hx-icon.png" alt="" class="icon" />
          小程序
          <div class="support-info mini-program" v-if="showMiniProgramModal">
            <div class="line1">海星合伙人小程序</div>
            <div class="line2">微信扫一扫</div>
            <img src="@/assets/images/starfish/hx-code.png" alt="" />
          </div>
        </div>
        <div
          class="support-center"
          @mouseenter="showSupportModal = true"
          @mouseout="showSupportModal = false"
          @click="goSupportCenter"
        >
          <img src="@/assets/images/workBench/help-icon.png" alt="" class="icon" />
          <div class="support-info" v-if="showSupportModal">
            <div class="des">点击跳转帮助中心,自己找答案</div>
            <div class="customerService">
              <div><span class="label-name">专属顾问</span> 为您答疑解惑</div>
              <div class="zkkf-user">
                <img src="@/assets/images/defaultCustomer.png" alt="" />
                <div class="zkkf-phone">
                  <p>{{ UniqueCustomerServiceName }}</p>
                  <p>{{ UniqueCustomerServicePhone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a @click="goMsg" class="bell">
          <span v-if="bellNum > 0" id="numberOfNotifications" class="number">{{ bellNum }}</span>
        </a>

        <!-- <div
          class="vip-icon"
          v-if="membershipInfo && membershipInfo.agentName"
          @click="$router.push('/membership-center')"
        ></div> -->
        <span class="header-user">
          <img :src="headPic ? headPic : headUrl" alt="用户头像" title="用户头像" />
          <span class="header-user-name">
            <span>&nbsp;{{ username }}</span>
            <div class="server-list">
              <a href="/partner-center" class="icon-hhrzx">合伙人中心</a>
              <a :href="`${routeBaseUrl}/home/index`" class="icon-pt">切换为普通用户</a>
              <div class="line"></div>
              <a class="icon-grxx" @click="goPersonal">个人信息</a>
              <a class="icon-wdsy" @click="goPath(`earning`, 1)">我的收益</a>
              <a class="icon-wdqd" @click="goPath('/beans-and-balance', 1)">我的资产</a>
              <a class="icon-wdtd" @click="goPath(`/my-team`, 1)">我的团队</a>
              <a @click="goPath(`/account-safe`, 1)" class="icon-zhaq">账户安全</a>
              <!-- <a href="http://mzw.quandashi.com/" class="icon-dlwz" target="_blank">独立网站</a> -->
              <div class="line"></div>
              <a class="icon-tcdl" @click="sign_out">退出登录</a>
            </div>
          </span>
        </span>
      </div>
    </div>

    <!-- 邀请有礼广告弹窗 -->
    <team-invite-dialog
      :dialogVisible="teamInviteDialogVisible"
      @close="teamInviteDialogVisible = false"
    />

    <!-- 海星小程序弹窗 -->
    <el-dialog
      :visible.sync="starfishDialogVisible"
      class="starfish-mini-wrapper"
      :close-on-click-modal="false"
      append-to-body
      :show-close="false"
      width="640px"
    >
      <img src="@/assets/images/starfish/hx-bg.png" alt="" />
      <img
        src="@/assets/images/starfish/close-icon.png"
        alt=""
        class="close-icon"
        @click="starfishDialogVisible = false"
      />
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isHhr } from "@/api/partnerInform";
import baseHeadImg from "@/assets/images/bg-6.png";
import { getUniqueCustomerService } from "@/api/customer";
import { isSaasMember } from "@/api/membership";
import teamInviteDialog from "@/views/starfishPlan/components/teamInviteDialog.vue";
import jsCookie from "js-cookie";
import { readAllMsg } from "@/views/userMessage/api";

export default {
  name: "HeaderLayout",
  components: { teamInviteDialog },
  props: {
    headUrl: {
      type: String,
      default: ""
    }, // 用户头像
    username: {
      type: String,
      default: ""
    }, // 用户名
    bellNum: {
      type: Number,
      default: 0
    } // 头部导航栏消息数量
  },
  data() {
    return {
      // routeBaseUrl: process.env.NODE_ENV === "production" ? "https://www.quandashi.com" : "https://apre-www.quandashi.com",
      routeBaseUrl: process.env.VUE_APP_BASE_QDS,
      headPic: null,
      showSupportModal: false,
      UniqueCustomerServiceName: "", //专属客服名称
      UniqueCustomerServicePhone: "",
      nowRoutePath: "",
      isVIP: false,
      vipList: [],
      teamInviteDialogVisible: false,
      showMiniProgramModal: false,
      starfishDialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["isInside", "isPatentService", "membershipInfo", "clientAccount"])
  },
  watch: {
    "$route.path": {
      handler(n) {
        this.nowRoutePath = n;
      },
      immediate: true
    }
  },
  mounted() {
    console.log(this.$route);
    this.getAvtor();
    this.queryUniqueCustomerService();
    this.checkIsVIP();
  },
  methods: {
    sign_out() {
      window.location.href = `${this.routeBaseUrl}/index/logout`;
    },
    async getAvtor() {
      let params = {
        userIde: this.common.getUserInfo().userId,
        inputType: 1
      };
      if (params.userIde) {
        try {
          let res = await isHhr(params);
          window.sessionStorage.setItem("isAgent", res.agent.status);
          window.sessionStorage.setItem("hhrInfo", JSON.stringify(res));
          this.headPic =
            (res.agent.headPic && process.env.VUE_APP_BASE_FILE + res.agent.headPic) || baseHeadImg;
        } catch (error) {
          window.location.href = process.env.VUE_APP_BASE_QDS;
        }
      }
    },
    async goHomePage() {
      let params = {
        userIde: this.common.getUserInfo().userId,
        inputType: 1
      };
      if (params.userIde) {
        let res = await isHhr(params);
        // 0审核中,
        // 1通过,
        // 2审核不通过
        // 3变更待审核,
        // 5资料填写中(未填写完成状态)
        // 6审核不通过永久删除状态',
        let status = res.agent.status;
        switch (status) {
          case 1:
          case 3:
            window.location.href = `/`;
            break;
          case 0:
            this.$router.push({
              name: "partnerInformSubmitting"
            });
            break;
          case 2:
          case 5:
            this.$router.push({
              name: "identityChoose"
            });
            break;
          default:
            break;
        }
      } else {
        this.$router.push({
          name: "identityChoose"
        });
      }
    },
    goPersonal() {
      this.$router.push({
        name: "personalInform"
      });
    },
    async queryUniqueCustomerService() {
      let res = await getUniqueCustomerService({
        userIde: this.common.getUserInfo().userId
      });
      this.UniqueCustomerServiceName = res.name;
      this.UniqueCustomerServicePhone = res.phone;
    },
    goSupportCenter() {
      window.open(this.routeBaseUrl + "/help/index?url=" + window.location.href);
    },
    async goMsg() {
      try {
        if (this.bellNum) {
          await readAllMsg({
            userId: this.common.getUserInfo().userId
          });
          this.$parent.getInnerMsgCount();
        }
        this.$router.push({
          name: "userMessage"
        });
      } catch (error) {
        console.log(error);
      }
    },
    openPath(path) {
      window.open(path);
    },

    async checkIsVIP() {
      let res = await isSaasMember({
        agentIde: this.common.getUserInfo().userId
      });
      this.isVIP = res.flag;
      sessionStorage.setItem("isVIP", res.flag);
    },
    goInstitution() {
      // if (!this.clientAccount) {
      //   this.$message.warning(`您的自助递交权限暂未开放，请联系顾问咨询处理`);
      //   return;
      // }
      let url = this.$router.resolve({
        path: "/starfish/index"
      });
      window.open(url.href);
    },
    goPath(path, flag) {
      if (flag) {
        window.open(path);
      } else {
        this.$router.push(path);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import url(../assets/css/reset.css);
.header-box {
  width: 1240px;
  height: 60px;
  margin: auto;
  position: relative;

  .logo {
    padding-top: 15px;
    height: 100%;
    color: #ff7200;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;

    img {
      width: 126px;
      vertical-align: middle;
    }
  }
  .icon-vip {
    height: 14px;
    position: relative;
    top: -12px;
    left: -8px;
  }
  .header-nav {
    padding-left: 215px;
    height: 100%;
    // line-height: 60px;
    position: relative;

    .qds-dropdown {
      float: left;
      margin-right: 60px;
      position: relative;

      .drop-btn {
        text-align: center;
        cursor: pointer;
        overflow: hidden;

        .text {
          display: inline-block;
          float: none;
          font-size: 18px;
          vertical-align: middle;
          line-height: 60px;
        }
      }
      .drop-btn.active .text {
        // border-bottom: 3px solid #ff7200;
        color: #ff7200;
        font-weight: bold;
      }
    }

    .header-box-pay-right {
      display: flex;
      align-items: center;
      height: 100%;
      margin-top: 0;
      float: right;
      // line-height: 60px;
      .bell {
        display: inline-block;
        position: relative;
        margin-right: 30px;
        width: 24px;
        height: 24px;
        background-image: url(../assets/images/workBench/bell-icon.png);
        background-size: 100% 100%;
        background-size: cover;
        vertical-align: middle;
        cursor: pointer;

        .number {
          position: absolute;
          right: -5px;
          top: -5px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          background-color: #e90027;
          text-align: center;
          border-radius: 50%;
        }
      }
      .vip-icon {
        display: inline-block;
        background-image: url(~@/assets/images/membership/vip-icon.png);
        background-size: 100% 100%;
        height: 34px;
        width: 84px;
        margin-right: 12px;
        cursor: pointer;
      }

      .header-user {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        padding-right: 7px;
        background: url(../assets/images/icon-more-arrow.png) no-repeat right bottom;
        background-size: 6px;

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          vertical-align: middle;
        }

        .header-user-name {
          position: relative;
          display: inline-block;
          color: #666;
          vertical-align: middle;
          padding-right: 0;
          background: none;
        }

        .server-list {
          right: -5px;
          left: auto;
          padding-top: 10px;
          padding-bottom: 10px;
          width: 200px;
          height: auto;
          box-shadow: 0px 0px 6px 0px rgba(11, 17, 60, 0.1);
          border-radius: 2px;
          position: absolute;
          background-color: #fff;
          z-index: 99;
          display: none;
        }

        a {
          padding-left: 54px;
          line-height: 2.7em;
          font-size: 13px;
          display: block;
          color: #666;
        }

        .line {
          margin-top: 7px;
          margin-bottom: 7px;
          height: 4px;
          padding: 0;
          background-color: #f7f7f8;
        }
        span {
          cursor: pointer;
        }

        .icon-grxx {
          background: url(../assets/images/icon-grxx.png?v=1) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-grzx {
          background: url(../assets/images/icon-grzx.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-hhrzx {
          background: url(../assets/images/icon-hhrzx.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-wdsy {
          background: url(../assets/images/icon-wdsy.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-wdqd {
          background: url(../assets/images/icon-wdqd.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-wdtd {
          background: url(../assets/images/icon-wdtd.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-zhaq {
          background: url(../assets/images/icon-zhaq.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-dlwz {
          background: url(../assets/images/icon-dlwz.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-pt {
          background: url(../assets/images/icon-pt.png) no-repeat 20px center;
          background-size: 18px auto;
        }
        .icon-tcdl {
          background: url(../assets/images/icon-tcdl.png) no-repeat 20px center;
          background-size: 18px auto;
          cursor: pointer;
        }
      }

      .header-user:hover .server-list {
        display: block;
      }

      .support-center {
        display: inline-block;
        margin-right: 30px;
        position: relative;
        cursor: pointer;
        img.icon {
          height: 24px;
          width: 24px;
          vertical-align: middle;
        }
        .support-info {
          z-index: 99;
          height: 161px;
          width: 222px;
          background-color: #fff;
          border-radius: 10px;
          border: 1px solid #e4e4e4;
          position: absolute;
          left: 0px;
          top: 34px;

          display: flex;
          flex-direction: column;
          .des {
            flex: 1;
            border-bottom: 4px solid #f7f7f8;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #999999;
          }
          .customerService {
            flex: 2;
            font-size: 12px;
            color: #666666;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-left: 32px;
            .label-name {
              font-size: 13px;
              color: #333333;
            }
            .zkkf-user {
              color: #333333;
              display: flex;
              img {
                width: 40px;
                height: 40px;
                margin-right: 12px;
              }
              .zkkf-phone {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
        .support-info.mini-program {
          width: 220px;
          height: 248px;
          background: #ffffff;
          box-shadow: 0px 2px 16px 0px rgba(54, 40, 27, 0.1);
          border-radius: 10px;
          border: 1px solid #e4e4e4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          z-index: 99;
          .line1 {
            font-size: 14px;
            color: #333333;
            margin-bottom: 6px;
          }
          .line2 {
            font-size: 14px;
            color: #999999;
            margin-bottom: 16px;
          }
          img {
            width: 136px;
            height: 136px;
          }
        }
      }
      .gift-invt {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 26px;
        color: #a97b3d;
        cursor: pointer;
        img {
          width: 50px;
          height: 50px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
<style lang="scss" >
.starfish-mini-wrapper {
  .el-dialog {
    background: transparent;
    box-shadow: none;
  }
  .el-dialog__body {
    padding: 0;
    position: relative;
  }
  .el-dialog__header {
    padding: 0;
  }
  img {
    width: 640px;
    height: 480px;
  }
  .close-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
}
</style>
