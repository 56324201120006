const assessRouters = [
  {
    path: "/brand-assess",
    name: "brandAssess",
    component: () => import("@/views/brandAssess/index.vue"),
    meta: {
      title: "商标估价",
      noSide: true
    }
  },
  {
    path: "/brand-assess-consume",
    name: "brandAssessConsume",
    component: () => import("@/views/brandAssess/consumption.vue"),
    meta: {
      title: "充值消耗明细",
      noSide: true,
      keepAlive: false
    }
  }
];

export default assessRouters;
