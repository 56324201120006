import { getData } from "./index";
import { uploadFile } from "./httpRequest";
import axios from "@/utils/axios";

// 根据手机号查询是否入驻合伙人接口：
export function getIsSettled(params) {
  return getData(params, "user/tuser/searchAgentByPhone", "post");
}

// 根据手机号查询是否审核中：
export function getVerifyStatus(params) {
  return getData(params, "legend4/agentinfo/getVerifyStatus", "post");
}

// 验证码接口：/user/tuser/sendCode
export function getVerificationCode(data) {
  return getData(data, "user/tuser/sendCode", "post");
}
//验证验证是否正确
export function checkerificationCode(data) {
  return getData(data, "user/tuser/returnValidatorStatus", "post");
}
//获取行业列表
export function getIndustryList(data) {
  return getData(data, "user/queryAgentLabel", "post");
}
// 查看协议文档接口
export function queryAgreementsFile(data) {
  return getData(data, "user/queryAgreements", "post");
}
//同意协议接口(terminalType1)
export function userAgree(data) {
  return getData(data, "user/userAgree", "post");
}
//用户是否已同意协议
export function whetherTick(data) {
  return getData(data, "user/whetherTick", "post");
}

//上传文件
export function uploadFiles(params) {
  return uploadFile(params, "upload/upload", "post");
}

//提交接口：
export function saveHhr(params) {
  // return getData(params, "user/saveHhr", "post", "认证提交");
  return axios.request({
    url: "user/saveHhr",
    method: "post",
    data: params
  });
}

export function isHhr(params) {
  return getData(params, "/user/hhrInformation", "post");
}
