import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    applicantInfo: null,
    commentInfo: null,
    blackInfo: null,
    isShow: 0,
    nowRoute: null,
    smartOrder_info: null,
    allRigions: null,
    membershipInfo: null,
    isInside: false,
    isPatentService: false,
    clientAccount: false,
    permissionList: null,
    brandMagDOC: 0,
    customerLossCount: 0,
    selectedClueList: [],
    partnerStatus: 0 // 合伙人状态
  },
  getters: {
    applicantInfo: state => state.applicantInfo,
    commentInfo: state => state.commentInfo,
    blackInfo: state => state.blackInfo,
    isShow: state => state.isShow,
    nowRoute: state => state.nowRoute,
    smartOrder_info: state => state.smartOrder_info,
    allRigions: state => state.allRigions,
    membershipInfo: state => state.membershipInfo,
    isInside: state => state.isInside,
    isPatentService: state => state.isPatentService,
    clientAccount: state => state.clientAccount,
    permissionList: state => state.permissionList,
    brandMagDOC: state => state.brandMagDOC,
    customerLossCount: state => state.customerLossCount,
    selectedClueList: state => state.selectedClueList,
    partnerStatus: state => state.partnerStatus
  },
  mutations: {
    SET_APPLICANTINFO: (state, info) => {
      state.applicantInfo = info;
    },
    SET_COMMENTINFO: (state, info) => {
      state.commentInfo = info;
    },
    SET_BLACKINFO: (state, info) => {
      state.blackInfo = info;
    },
    SET_ISSHOW: (state, info) => {
      state.isShow = info;
    },
    SET_NOWROUTE: (state, info) => {
      state.nowRoute = info;
    },
    SET_SMARTORDER_INFO: (state, info) => {
      state.smartOrder_info = info;
    },
    SET_ALLRIGIONS: (state, info) => {
      state.allRigions = info;
    },
    SET_MEMBERSHIPINFO: (state, info) => {
      state.membershipInfo = info;
    },
    SET_ISINSIDE: (state, info) => {
      state.isInside = info;
    },
    SET_ISPATENTSERVICE: (state, info) => {
      state.isPatentService = info;
    },
    SET_CLIENTACCOUNT: (state, info) => {
      state.clientAccount = info;
    },
    SET_PERMISSIONLIST: (state, info) => {
      state.permissionList = info;
    },
    SET_BRANDMAGDOC: (state, info) => {
      state.brandMagDOC = info;
    },
    SET_CUSTOMERLOSSCOUNT: (state, info) => {
      state.customerLossCount = info;
    },
    SET_SELECTEDCLUELIST: (state, info) => {
      state.selectedClueList = info;
    },
    SET_PARNTERSTATUS: (state, info) => {
      state.partnerStatus = info;
    }
  },
  actions: {
    setApplicantInfo({ commit }, info) {
      commit("SET_APPLICANTINFO", info);
    },
    setCommentInfo({ commit }, info) {
      commit("SET_COMMENTINFO", info);
    },
    setBlackInfo({ commit }, info) {
      commit("SET_BLACKINFO", info);
    },
    setIsShow({ commit }, info) {
      commit("SET_ISSHOW", info);
    },
    setNowRoute({ commit }, info) {
      commit("SET_NOWROUTE", info);
    },
    setSmartOrderInfo({ commit }, info) {
      commit("SET_SMARTORDER_INFO", info);
    },
    setAllReginos({ commit }, info) {
      commit("SET_ALLRIGIONS", info);
    },
    setMembershipInfo({ commit }, info) {
      commit("SET_MEMBERSHIPINFO", info);
    },
    setIsInside({ commit }, info) {
      commit("SET_ISINSIDE", info);
    },
    setIsPatentService({ commit }, info) {
      commit("SET_ISPATENTSERVICE", info);
    },
    setClientAccount({ commit }, info) {
      commit("SET_CLIENTACCOUNT", info);
    },
    setPermissionList({ commit }, info) {
      commit("SET_PERMISSIONLIST", info);
    },
    setBrandMagDOC({ commit }, info) {
      commit("SET_BRANDMAGDOC", info);
    },
    setCustomerLossCount({ commit }, info) {
      commit("SET_CUSTOMERLOSSCOUNT", info);
    },
    setSelectedClueList({ commit }, info) {
      commit("SET_SELECTEDCLUELIST", info);
    },
    setPartnerStatus({ commit }, info) {
      commit("SET_PARNTERSTATUS", info);
    }
  },
  modules: {}
});
