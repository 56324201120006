const applicantsRoutes = [
  {
    path: "/applicants",
    name: "applicants",
    component: () => import("@/views/toolList/applicants/index.vue"),
    meta: {
      title: "常用申请人"
    }
  },
  {
    path: "/applicants-new",
    name: "applicantsNew",
    component: () => import("@/views/toolList/applicants/index-new.vue"),
    meta: {
      title: "常用申请人新版"
    }
  },
  {
    path: "/applicants-edit",
    name: "applicantsEdit",
    component: () => import("@/views/toolList/applicants/edit.vue"),
    meta: {
      title: "常用申请人编辑"
    }
  },
  {
    path: "/applicants-edit-new",
    name: "applicantsEditNew",
    component: () => import("@/views/toolList/applicants/edit-new.vue"),
    meta: {
      title: "常用申请人编辑新版"
    }
  }
];

export default applicantsRoutes;
