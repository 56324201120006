const smartComposeRoutes = [
  {
    path: "/smart-compose",
    name: "smartCompose",
    component: () => import("@/views/smartCompose/index.vue"),
    meta: {
      title: "智能撰写"
    }
  },
  {
    path: "/compose-detail",
    name: "composeDetail",
    component: () => import("@/views/smartCompose/analysisDetail.vue"),
    meta: {
      title: "查看驳回通知分析书"
    }
  },
  {
    path: "/reason-detail",
    name: "reasonDetail",
    component: () => import("@/views/smartCompose/reasonDetail.vue"),
    meta: {
      title: "查看驳回复审理由书"
    }
  },
  {
    path: "/compose-consumption",
    name: "composeConsumption",
    component: () => import("@/views/smartCompose/consumption.vue"),
    meta: {
      title: "智能撰写充值消耗明细"
    }
  }
];

export default smartComposeRoutes;
