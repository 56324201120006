var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"hhr-leftNav"}},[_c('ul',{staticClass:"nav-box globalScrool"},[(_vm.isInside)?_c('li',{staticClass:"menu",attrs:{"data-type":"inside"}},[_c('div',{staticClass:"menu-header",attrs:{"data-item":"inside"},on:{"click":_vm.isOpen}},[_c('i',{class:_vm.insideIcon ? 'icon' : 'icon-arrowdown'}),_c('span',{staticClass:"text"},[_vm._v("内部办公")])]),_c('ul',{staticClass:"menu-body",class:_vm.insideIcon ? 'menu-body-hidden' : ''},_vm._l((_vm.insideList),function(item,index){return _c('li',{key:item.name,class:item.isSelected ? 'selected' : '',on:{"click":function($event){return _vm.navClick(item, index, 'insideList')}}},[_c('a',{staticClass:"text"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),_c('li',{staticClass:"menu",attrs:{"data-type":"server"}},[_c('div',{staticClass:"menu-header",attrs:{"data-item":"server"},on:{"click":_vm.isOpen}},[_c('i',{class:_vm.serverIcon ? 'icon' : 'icon-arrowdown'}),_c('span',{staticClass:"text"},[_vm._v("工作中心")])]),_c('ul',{staticClass:"menu-body",class:_vm.serverIcon ? 'menu-body-hidden' : ''},_vm._l((_vm.serverList),function(item,index){return _c('li',{key:item.name,class:{
            selected: item.isSelected,
            'diy-pop-class': item.name.indexOf('流失提醒') != -1
          },on:{"click":function($event){return _vm.navClick(item, index, 'serverList')}}},[_c('a',{staticClass:"text"},[_vm._v(_vm._s(item.name)+" "),(item.name == '流失提醒')?_c('span',[_vm._v("("+_vm._s(_vm.customerLossCount)+")")]):_vm._e()])])}),0)]),_c('li',{staticClass:"menu",attrs:{"data-type":"tool"}},[_c('div',{staticClass:"menu-header",attrs:{"data-item":"tool"},on:{"click":_vm.isOpen}},[_c('i',{class:_vm.toolIcon ? 'icon' : 'icon-arrowdown'}),_c('span',{staticClass:"text"},[_vm._v("常用工具")])]),_c('ul',{staticClass:"menu-body",class:_vm.toolIcon ? 'menu-body-hidden' : ''},_vm._l((_vm.toolList),function(item,index){return _c('li',{key:item.name,class:{
            'rel-tool': item.new,
            selected: item.isSelected,
            'patent-menu': item.name == '专利年费监测'
          },on:{"click":function($event){return _vm.navClick(item, index, 'toolList')}}},[_c('a',{staticClass:"text"},[_vm._v(" "+_vm._s(item.name)+" "),_c('i',{staticClass:"icon-new",class:item.new ? '' : 'icon-new-hidden'})])])}),0)]),_c('li',{staticClass:"menu",attrs:{"data-type":"news"}},[_c('div',{staticClass:"menu-header",attrs:{"data-item":"news"},on:{"click":_vm.isOpen}},[_c('i',{class:_vm.newsIcon ? 'icon' : 'icon-arrowdown'}),_c('span',{staticClass:"text"},[_vm._v("知产课堂")])]),_c('ul',{staticClass:"menu-body",class:_vm.newsIcon ? 'menu-body-hidden' : ''},_vm._l((_vm.newsList),function(item,index){return _c('li',{key:item.name,on:{"click":function($event){return _vm.navClick(item, index, 'newsList')}}},[_c('a',{staticClass:"text"},[_vm._v(_vm._s(item.name))])])}),0)])]),(!_vm.serverIcon)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvPop),expression:"showAdvPop"}],staticClass:"diy-popper",class:{ 'no-date': !_vm.customerLossCount },on:{"click":function($event){return _vm.closeAdvPop(1)}}},[_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){$event.stopPropagation();return _vm.closeAdvPop(0)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.customerLossCount),expression:"customerLossCount"}]},[_vm._v(_vm._s(_vm.customerLossCount))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }