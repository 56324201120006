const productPackageRoutes = [
  {
    path: "/product-package",
    name: "productPackage",
    component: () => import("@/views/productPackage/index.vue"),
    meta: {
      title: "产品包首页",
      noSide: true
    }
  },
  {
    path: "/package-order",
    name: "packageOrder",
    component: () => import("@/views/productPackage/packageOrder.vue"),
    meta: {
      title: "产品包下单",
      keepAlive: true
    }
  },
  {
    path: "/package-order-list",
    name: "packageOrderList",
    component: () => import("@/views/productPackage/packageOrderList.vue"),
    meta: {
      title: "产品包订单列表",
      noSide: true
    }
  },
  {
    path: "/package-order-info",
    name: "packageOrderInfo",
    component: () => import("@/views/productPackage/packageOrderInfo.vue"),
    meta: {
      title: "产品包订单详情",
      noSide: true
    }
  },
  {
    path: "/package-service-voucher",
    name: "serviceVoucher",
    component: () => import("@/views/productPackage/serviceVoucher.vue"),
    meta: {
      title: "我的服务券"
    }
  },
  {
    path: "/confirm-package-orders",
    name: "confirmPackageOrders",
    component: () => import("@/views/productPackage/components/confirmPackage"),
    meta: {
      title: "确认订单"
    }
  }
];

export default productPackageRoutes;
