const workbenchRoutes = [
  {
    path: "/partner-center",
    name: "partnerCenter",
    component: () => import("@/views/workbench/index.vue"),
    meta: {
      title: "工作台"
    }
  },
  {
    path: "/beans-and-balance",
    name: "beansAndBalance",
    component: () => import("@/views/workbench/beansAndBalanceDetail.vue"),
    meta: {
      title: "权豆余额明细"
    }
  },
  {
    path: "/earning",
    name: "earning",
    component: () => import("@/views/workbench/earnings.vue"),
    meta: {
      title: "收益明细"
    }
  },
  {
    path: "/set-payment-password",
    name: "setPaymentPassword",
    component: () => import("@/views/workbench/setPaymentPassword.vue"),
    meta: {
      title: "设置支付密码"
    }
  },
  {
    path: "/set-login-password",
    name: "setLoginPassword",
    component: () => import("@/views/workbench/components/loginSafe.vue"),
    meta: {
      title: "设置登录密码"
    }
  },
  {
    path: "/set-login-mail",
    name: "setLoginMail",
    component: () => import("@/views/workbench/components/mailSafe.vue"),
    meta: {
      title: "设置邮箱"
    }
  },
  {
    path: "/set-login-phone",
    name: "setLoginPhone",
    component: () => import("@/views/workbench/components/phoneSafe.vue"),
    meta: {
      title: "设置手机号"
    }
  },
  {
    path: "/bankcard",
    name: "bankcard",
    component: () => import("@/views/workbench/bankcard.vue"),
    meta: {
      title: "我的银行卡"
    }
  },
  {
    path: "/account-safe",
    name: "accountSafe",
    component: () => import("@/views/workbench/accountSafe.vue"),
    meta: {
      title: "账户安全",
      hidden: true
    }
  },
  {
    path: "/my-team",
    name: "myTeam",
    component: () => import("@/views/workbench/myTeam/index.vue"),
    meta: {
      title: "我的团队"
    }
  }
];

export default workbenchRoutes;
