const monitorRouters = [
  {
    path: "/brand-monitor",
    name: "brandMonitor",
    component: () =>
      import("@/views/workCenter/brandMonitor/monitorMsgList.vue"),
    meta: {
      title: "商标监测消息列表",
      noSide: true
    }
  },
  {
    path: "/brand-monitor-task",
    name: "brandMonitorTask",
    component: () =>
      import("@/views/workCenter/brandMonitor/monitorTaskList.vue"),
    meta: {
      title: "商标监测任务列表",
      noSide: true,
      keepAlive: false
    }
  },
  {
    path: "/payment-history",
    name: "paymentHistory",
    component: () =>
      import("@/views/workCenter/brandMonitor/components/paymentHistory.vue"),
    meta: {
      title: "付款记录",
      noSide: true,
      keepAlive: true
    }
  },
  {
    path: "/brand-monitor-task-info",
    name: "brandMonitorTaskInfo",
    component: () =>
      import("@/views/workCenter/brandMonitor/monitorTaskInfo.vue"),
    meta: {
      title: "商标监测任务详情",
      noSide: true,
      keepAlive: true
    }
  },
  {
    path: "/brand-monitor-setting",
    name: "monitorSetting",
    component: () =>
      import("@/views/workCenter/brandMonitor/monitorSetting.vue"),
    meta: {
      title: "商标监测设置",
      noSide: true
    }
  },
  {
    path: "/consumption-detail",
    name: "consumptionDetail",
    component: () => import("@/views/workCenter/brandMonitor/consumption.vue"),
    meta: {
      title: "充值消耗明细",
      noSide: true
    }
  },
  {
    path: "/new-charge-card",
    name: "newChargeCard",
    component: () =>
      import(
        "@/views/workCenter/brandMonitor/newBrandMonitor/newChargeCard.vue"
      ),
    meta: {
      title: "客户监测充值",
      noSide: true
    }
  },
  {
    path: "/open-by-card",
    name: "openByCard",
    component: () =>
      import("@/views/workCenter/brandMonitor/newBrandMonitor/openByCard.vue"),
    meta: {
      title: "开通监测特权",
      noSide: true
    }
  },
  {
    path: "/analysis-report",
    name: "analysisReport",
    component: () =>
      import(
        "@/views/workCenter/brandMonitor/newBrandMonitor/newAnalysisReport.vue"
      ),
    meta: {
      title: "企业分析报告",
      noSide: true
    }
  }
];

export default monitorRouters;
