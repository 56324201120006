import { getData } from "@/api/index";

//获取消息列表
export function getInnerMsgList(params) {
  return getData(params, "/common/getInnerMsgList", "post");
}
//删除消息
export function updateMsgDelete(params) {
  return getData(params, "/common/updateMsgDelete", "post");
}

//全部已读消息
export function readAllMsg(params) {
  return getData(params, "/common/innerMsgAllRead", "post");
}
