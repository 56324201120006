import productPackageRoutes from "./product-package";
import smartOrderRoutes from "./smart-order";
const workCenterRoutes = [
  {
    path: "/brand-register",
    name: "brandRegister",
    component: () => import("@/views/brandRegisterNew/home.vue"),
    meta: {
      title: "商标注册下单"
    }
  },
  {
    path: "/brand-register-old",
    name: "brandRegisterOld",
    component: () => import("@/views/brandRegister/home.vue"),
    meta: {
      title: "商标注册下单-旧版"
    }
  },
  {
    path: "/work-business-order",
    name: "workBusinessOrder",
    component: () => import("@/views/workCenter/placeOrder/workBusinessOrder"),
    meta: {
      title: "工作中心业务下单"
    }
  },
  {
    path: "/confirm-orders",
    name: "confirmOrders",
    component: () => import("@/views/workCenter/placeOrder/confirmOrders"),
    meta: {
      title: "确认订单",
      keepAlive: true
    }
  },
  {
    path: "/partner-order",
    name: "partnerOrder",
    component: () =>
      import("@/views/workCenter/orderController/partnerOrder.vue"),
    meta: {
      title: "工作中心订单管理",
      keepAlive: true
    }
  },
  {
    path: "/order-details",
    name: "orderDetails",
    component: () =>
      import("@/views/workCenter/orderController/orderDetails.vue"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/copyright-order",
    name: "copyrightOrder",
    component: () => import("@/views/workCenter/copyrightOrder/index.vue"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/AF-order",
    name: "AForder",
    component: () => import("@/views/workCenter/AForder/index.vue"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/AN-order",
    name: "ANorder",
    component: () => import("@/views/workCenter/ANorder/index.vue"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/serve-Order",
    name: "serveOrder",
    component: () => import("@/views/workCenter/serveOrder"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/apply-invoice",
    name: "applyInvoice",
    component: () => import("@/views/workCenter/applyInvoice/applyInvoice.vue"),
    meta: {
      title: "发票申请"
    }
  },
  {
    path: "/invoice-submit",
    name: "invoiceSubmit",
    component: () => import("@/views/workCenter/applyInvoice/submit.vue"),
    meta: {
      title: "发票申请"
    }
  },
  {
    path: "/ahead-apply-invoice",
    name: "aheadApplyInvoice",
    component: () =>
      import("@/views/workCenter/applyInvoice/aheadApplyInvoice.vue"),
    meta: {
      title: "提前申请开票"
    }
  },
  {
    path: "/mail-address",
    name: "mailAddress",
    component: () => import("@/views/workCenter/mailAddress/index.vue"),
    meta: {
      title: "邮寄地址管理"
    }
  },
  {
    path: "/office-manage",
    name: "officeManage",
    component: () => import("@/views/workCenter/officeManage/officeManage.vue"),
    meta: {
      title: "官文管理"
    }
  },
  {
    path: "/export-record",
    name: "exportRecord",
    component: () => import("@/views/workCenter/exportRecord/exportRecord.vue"),
    meta: {
      title: "导出记录"
    }
  },
  {
    path: "/customer-manage",
    name: "customerManage",
    component: () => import("@/views/workCenter/customerManage"),
    meta: {
      title: "客户管理"
    }
  },
  {
    path: "/customer-detail",
    name: "customerDetail",
    component: () =>
      import("@/views/workCenter/customerManage/customer-detail.vue"),
    meta: {
      title: "客户详情"
    }
  },
  {
    path: "/customer-add",
    name: "customerAdd",
    component: () =>
      import("@/views/workCenter/customerManage/customer-add.vue"),
    meta: {
      title: "新增客户"
    }
  },
  {
    path: "/personal-inform",
    name: "personalInform",
    component: () => import("@/views/personalInform/index.vue"),
    meta: {
      title: "个人信息"
    }
  },
  {
    path: "/user-message",
    name: "userMessage",
    component: () => import("@/views/userMessage/index.vue"),
    meta: {
      title: "消息通知"
    }
  },
  ...productPackageRoutes,
  ...smartOrderRoutes,
  {
    path: "/order-management",
    name: "orderManagement",
    component: () => import("@/views/workCenter/orderManagement/index.vue"),
    meta: {
      title: "订单管理"
    }
  },
  {
    path: "brand-manage",
    name: "brandManage",
    component: () => import("@/views/workCenter/brandManagement/detail.vue"),
    meta: {
      title: "订单管理",
      hidden: true
    }
  },
  {
    path: "brand-manage-edit",
    name: "brandManageEdit",
    component: () =>
      import("@/views/workCenter/brandManagement/pre-service.vue"),
    meta: {
      title: "订单编辑",
      hidden: true
    }
  },
  {
    path: "/order-assistant",
    name: "orderAssistant",
    component: () => import("@/views/workCenter/orderAssistant/index.vue"),
    meta: {
      title: "订单助手"
    }
  },
  {
    path: "/customer-loss",
    name: "customerLoss",
    component: () => import("@/views/workCenter/customerLoss/index.vue"),
    meta: {
      title: "流失提醒"
    }
  }
];

export default workCenterRoutes;
