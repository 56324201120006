const customerClueRoutes = [
  {
    path: "/customer-clue-home",
    name: "customerClueHome",
    component: () => import("@/views/toolList/customerClueHome/index.vue"),
    meta: {
      title: "客户线索首页",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/customer-clue-home"
    }
  },
  {
    path: "/customer-clue-consume",
    name: "customerClueConsume",
    component: () =>
      import("@/views/toolList/customerClueHome/consumption.vue"),
    meta: {
      title: "客户线索-充值记录",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/customer-clue-home"
    }
  },
  {
    path: "/view-report",
    name: "viewReport",
    component: () =>
      import(
        "@/views/toolList/customerClueHome/components/riskReport/viewReport.vue"
      ),
    meta: {
      title: "风险报告-模板",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/customer-clue-home"
    }
  },
  {
    path: "/customer-clue-new",
    name: "customerClueNew",
    component: () => import("@/views/toolList/customerClueNew/index.vue"),
    meta: {
      title: "新版客户线索",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/customer-clue-new"
    }
  }
];
export default customerClueRoutes;
