const membershipRoutes = [
  {
    path: "membership-center",
    name: "membershipCenter",
    component: () => import("@/views/membership"),
    meta: {
      title: "会员中心",
      keepAlive: true
    }
  },
  {
    path: "membership-consumption",
    name: "membershipConsumption",
    component: () => import("@/views/membership/consumption.vue"),
    meta: {
      title: "会员中心充值消耗明细"
    }
  },
  {
    path: "membership-record",
    name: "membershipRecord",
    component: () => import("@/views/membership/record.vue"),
    meta: {
      title: "会员开通记录"
    }
  },
  {
    path: "membership-categories",
    name: "membershipCategories",
    component: () => import("@/views/membership/membershipCategories.vue"),
    meta: {
      title: "会员方案",
      noSide: true
    }
  }
];

export default membershipRoutes;
