import axios from "@/utils/axios";
import { Message } from "element-ui";
const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://phoenix.quandashi.com"
    : "https://apre-phoenix.quandashi.com";
// 获取默认申请人列表
export const getApplicantList = `${baseUrl}/common/applicantList`;
// 跳转baseUrl
export const routeBaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.quandashi.com"
    : "https://apre-www.quandashi.com";
// 获取头部导航栏未读消息数
export const getInnerMsgCount = `${baseUrl}/common/getInnerMsgCount`;

export async function getData(data, url, methods = "post", tips) {
  let res = await axios({
    url,
    method: methods,
    data
  });
  if (res.code == "ok" || res.subCode == 10002) {
    if (tips) {
      Message.success(`${tips}成功！`);
    }
    return res.data;
  } else if (res.subCode == 10004) {
    // Message.warning(res.subMessage);
    return (window.location.href = `${process.env.VUE_APP_LOGIN_QDS}/?callback=${window.location.href}`);
  } else if (res.subCode == 10202) {
    return (window.location.href = routeBaseUrl);
  } else if (res.subCode != 10002) {
    // Message.warning(res.subMessage);
    Message.warning("系统异常，请稍后再试！");
    throw res;
  }
}

export async function getFullData(data, url, methods, tips) {
  let res = await axios({
    url,
    method: methods,
    data
  });
  if (res.code == "ok" || res.subCode == 10002) {
    if (tips) {
      Message.success(`${tips}成功！`);
    }
    return res;
  } else if (res.subCode != 10002) {
    Message.warning(res.subMessage);
    return false;
  }
}

export async function getWarningData(data, url, methods, tips) {
  let res = await axios({
    url,
    method: methods,
    data
  });
  if (
    res.code == "ok" ||
    res.subCode == 10002 ||
    (res.subCode == 10001 && res.data)
  ) {
    if (tips) {
      Message.success(`${tips}成功！`);
    }
    return res.data;
  } else if (res.subCode != 10002) {
    Message.warning(res.subMessage);
    return false;
  }
}

export async function getSubMessage(data, url, methods, tips) {
  let res = await axios({
    url,
    method: methods,
    data
  });
  if (res.code == "ok" || res.subCode == 10002 || res.subCode == 10001) {
    if (tips) {
      Message.success(`${tips}成功！`);
    }
    return res;
  } else if (res.subCode != 10002) {
    Message.warning(res.subMessage);
    return false;
  }
}
