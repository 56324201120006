const internalRouter = [
  {
    path: "/internal-business-order",
    name: "internalBusinessOrder",
    component: () => import("@/views/internalOffice/businessOrder"),
    meta: {
      title: "内部业务下单"
    }
  }
];

export default internalRouter;
