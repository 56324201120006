const workbenchRoutes = [
  {
    path: "beans-and-balance",
    name: "beansAndBalance2",
    component: () => import("@/views/workbench/beansAndBalanceDetail.vue"),
    meta: {
      title: "权豆余额明细",
      hidden: true
    }
  },
  {
    path: "earning",
    name: "earning2",
    component: () => import("@/views/workbench/earnings.vue"),
    meta: {
      title: "收益明细",
      hidden: true
    }
  },
  {
    path: "set-payment-password",
    name: "setPaymentPassword2",
    component: () => import("@/views/workbench/setPaymentPassword.vue"),
    meta: {
      title: "设置支付密码",
      hidden: true
    }
  },
  {
    path: "set-login-password",
    name: "setLoginPassword2",
    component: () => import("@/views/workbench/components/loginSafe.vue"),
    meta: {
      title: "设置登录密码",
      hidden: true
    }
  },
  {
    path: "set-login-mail",
    name: "setLoginMail2",
    component: () => import("@/views/workbench/components/mailSafe.vue"),
    meta: {
      title: "设置邮箱",
      hidden: true
    }
  },
  {
    path: "set-login-phone",
    name: "setLoginPhone2",
    component: () => import("@/views/workbench/components/phoneSafe.vue"),
    meta: {
      title: "设置手机号",
      hidden: true
    }
  },
  {
    path: "bankcard",
    name: "bankcard2",
    component: () => import("@/views/workbench/bankcard.vue"),
    meta: {
      title: "我的银行卡",
      hidden: true
    }
  },
  {
    path: "account-safe",
    name: "accountSafe2",
    component: () => import("@/views/workbench/accountSafe.vue"),
    meta: {
      title: "账户安全",
      hidden: true
    }
  },
  {
    path: "my-team",
    name: "myTeam2",
    component: () => import("@/views/workbench/myTeam/index.vue"),
    meta: {
      title: "我的团队",
      hidden: true
    }
  }
];

const customerClueHome = [
  {
    path: "customer-clue-home",
    name: "customerClueHome2",
    component: () => import("@/views/toolList/customerClueHome/index.vue"),
    meta: {
      title: "客户线索首页",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/customer-clue-home",
      hidden: true
    }
  },
  {
    path: "customer-clue-consume",
    name: "customerClueConsume2",
    component: () =>
      import("@/views/toolList/customerClueHome/consumption.vue"),
    meta: {
      title: "客户线索-充值记录",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/starfish/customer-clue-new",
      hidden: true
    }
  },
  //新版客户线索
  {
    path: "customer-clue-new",
    name: "customerClueNew2",
    component: () => import("@/views/toolList/customerClueNew/index.vue"),
    meta: {
      title: "新版客户线索首页",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/starfish/customer-clue-new",
      hidden: true
    }
  },
  {
    path: "single-customer-clue",
    name: "singleCustomerClue2",
    component: () =>
      import("@/views/toolList/customerClueNew/singleCustomerClue.vue"),
    meta: {
      title: "新版单客户线索",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/starfish/customer-clue-new",
      hidden: true
    }
  },
  {
    path: "send-reminder-message",
    name: "sendReminderMessage2",
    component: () =>
      import(
        "@/views/toolList/customerClueNew/components/groupMessage/index.vue"
      ),
    meta: {
      title: "群发提醒短信",
      noSide: true,
      headerBtn: "客户线索",
      headerLink: "/starfish/customer-clue-new",
      hidden: true
    }
  }
];

const assessRouters = [
  {
    path: "brand-assess",
    name: "brandAssess2",
    component: () => import("@/views/brandAssess/index.vue"),
    meta: {
      title: "商标估价",
      noSide: true,
      hidden: true
    }
  },
  {
    path: "brand-assess-consume",
    name: "brandAssessConsume2",
    component: () => import("@/views/brandAssess/consumption.vue"),
    meta: {
      title: "充值消耗明细",
      noSide: true,
      keepAlive: false,
      hidden: true
    }
  }
];
const orderRoutes = [
  {
    path: "brand-register",
    name: "brandRegister2",
    component: () => import("@/views/brandRegisterNew/home.vue"),
    meta: {
      title: "商标注册",
      icon: require("@/assets/images/starfish/brand-icon.png"),
      hidden: true,
      statistic: "商标注册-130017",
      fullPath: "/starfish/brand-register"
    }
  },
  {
    path: "brand-register-old",
    name: "brandRegisterOld2",
    component: () => import("@/views/brandRegister/home.vue"),
    meta: {
      title: "商标注册-旧",
      icon: require("@/assets/images/starfish/brand-icon.png"),
      hidden: true,
      statistic: "商标注册-130017",
      fullPath: "/starfish/brand-register-old"
    }
  },

  {
    path: "batch-register",
    name: "batchRegister",
    meta: {
      title: "商标批量注册",
      icon: require("@/assets/images/starfish/batch-register.png"),
      phpPath:
        process.env.VUE_APP_BASE_QDS + "/partner-order/hhr-batch-register",
      hidden: true
    }
  },
  {
    path: "package-order",
    name: "packageOrder2",
    component: () => import("@/views/productPackage/packageOrder.vue"),
    meta: {
      title: "其他商标业务",
      hidden: true,
      icon: require("@/assets/images/starfish/r-icon.png"),
      query: {
        activeVal: "1"
      },
      statistic: "其他商标业务-130019",
      fullPath: "/starfish/package-order?activeVal=1"
    }
  },
  {
    path: "package-order",
    name: "packageOrder2",
    component: () => import("@/views/productPackage/packageOrder.vue"),
    meta: {
      title: "专利业务",
      hidden: true,
      icon: require("@/assets/images/starfish/p-icon.png"),
      query: {
        activeVal: "2"
      },
      statistic: "专利业务-130023",
      fullPath: "/starfish/package-order?activeVal=2"
    }
  },
  {
    path: "package-order",
    name: "packageOrder2",
    component: () => import("@/views/productPackage/packageOrder.vue"),
    meta: {
      title: "版权业务",
      hidden: true,
      icon: require("@/assets/images/starfish/c-icon.png"),
      query: {
        activeVal: "3"
      },
      statistic: "版权业务-130027",
      fullPath: "/starfish/package-order?activeVal=3"
    }
  },
  {
    path: "package-order",
    name: "packageOrder2",
    component: () => import("@/views/productPackage/packageOrder.vue"),
    meta: {
      title: "企业服务",
      hidden: true,
      icon: require("@/assets/images/starfish/company-icon.png"),
      query: {
        activeVal: "5"
      },
      statistic: "企业服务-130031",
      fullPath: "/starfish/package-order?activeVal=5"
    }
  }
];

const smartComposeRoutes = [
  {
    path: "smart-compose",
    name: "smartCompose2",
    component: () => import("@/views/smartCompose/index.vue"),
    meta: {
      title: "智能撰写",
      hidden: true
    }
  },
  {
    path: "compose-detail",
    name: "composeDetail2",
    component: () => import("@/views/smartCompose/analysisDetail.vue"),
    meta: {
      title: "查看驳回通知分析书",
      hidden: true
    }
  },
  {
    path: "reason-detail",
    name: "reasonDetail2",
    component: () => import("@/views/smartCompose/reasonDetail.vue"),
    meta: {
      title: "查看驳回复审理由书",
      hidden: true
    }
  },
  {
    path: "compose-consumption",
    name: "composeConsumption2",
    component: () => import("@/views/smartCompose/consumption.vue"),
    meta: {
      title: "智能撰写充值消耗明细",
      hidden: true
    }
  }
];

const applicantsRoutes = [
  {
    path: "applicants",
    name: "applicants2",
    component: () => import("@/views/toolList/applicants/index.vue"),
    meta: {
      title: "常用申请人",
      hidden: true
    }
  },
  {
    path: "applicants-new",
    name: "applicantsNew2",
    component: () => import("@/views/toolList/applicants/index-new.vue"),
    meta: {
      title: "常用申请人新版",
      hidden: true
    }
  },
  {
    path: "applicants-edit",
    name: "applicantsEdit2",
    component: () => import("@/views/toolList/applicants/edit.vue"),
    meta: {
      title: "常用申请人编辑",
      hidden: true
    }
  },
  {
    path: "applicants-edit-new",
    name: "applicantsEditNew2",
    component: () => import("@/views/toolList/applicants/edit-new.vue"),
    meta: {
      title: "常用申请人编辑新版",
      hidden: true
    }
  }
];

const customerManageRoutes = [
  {
    path: "customer-manage",
    name: "customerManage2",
    component: () => import("@/views/workCenter/customerManage"),
    meta: {
      title: "客户管理",
      hidden: true
    }
  },
  {
    path: "customer-detail",
    name: "customerDetail2",
    component: () =>
      import("@/views/workCenter/customerManage/customer-detail.vue"),
    meta: {
      title: "客户详情",
      hidden: true
    }
  },
  {
    path: "customer-add",
    name: "customerAdd2",
    component: () =>
      import("@/views/workCenter/customerManage/customer-add.vue"),
    meta: {
      title: "新增客户",
      hidden: true
    }
  },
  {
    path: "customer-loss",
    name: "customerLoss2",
    component: () => import("@/views/workCenter/customerLoss/index.vue"),
    meta: {
      title: "流失提醒",
      hidden: true
    }
  }
];

export {
  workbenchRoutes,
  customerClueHome,
  assessRouters,
  orderRoutes,
  smartComposeRoutes,
  applicantsRoutes,
  customerManageRoutes
};
