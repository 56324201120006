import Axios from "axios";
import { Message } from "element-ui";

// import qs from "qs";
export const uploadFile = function (data, url) {
  return new Promise((resolve, reject) => {
    const axios = Axios.create({
      baseURL: `${process.env.VUE_APP_BASE_API}`,
      timeout: 30000
    });
    axios.interceptors.request.use(request => {
      return request;
    });
    // data.appKey = "quandashi2151283371";
    // data.timestamp = new Date().getTime();
    // data.signMethod = "md5";
    // data.executor = "6c7057467444384f3469445769686641b582b486436513d3d";
    // data.sign =
    //   new Date().getTime() + "" + Math.floor(Math.random() * 10000 + 1);
    axios
      .post(url, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        console.log("axios-error", error);
        Message.error("系统异常");
        reject(error);
      });
  });
};

export async function UrlToBlob(url) {
  const res = await Axios({
    baseURL: process.env.VUE_APP_BASE_FILE,
    method: "get",
    url,
    responseType: "blob"
  });
  return res.data;
}

//获取ip地址
export async function getXy(data) {
  let res = await Axios({
    baseURL: "/",
    url: `${process.env.VUE_APP_BASE_QDS_API}brace/tools/getXy`,
    method: "post",
    data
  });
  if (res.data.code == "00000") {
    return res.data.data;
  }
}
