<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { queryMembershipInfo, getListMemberPrivilege } from "@/api/membership";
import { hasInsidePermission, loginByUserId } from "@/api/header";
import { getCustomerLossList } from "@/api/customerLoss";
import jsCookie from "js-cookie";

export default {
  name: "App",
  components: {},
  created() {
    console.log("@vue/cli-service: ~5.0.0");
    this.getMembershipInfo();
    this.checkPermission();
    this.checkGrayscaleEnvironment();
    this.loginByUserId();
    this.fnPermission();
    this.queryCustomerLossCount();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setMembershipInfo",
      "setIsInside",
      "setIsPatentService",
      "setClientAccount",
      "setPermissionList",
      "setCustomerLossCount"
    ]),
    async getMembershipInfo() {
      if (this.common.getUserInfo().userId) {
        let res = await queryMembershipInfo({
          userId: this.common.getUserInfo().userId
        });
        this.setMembershipInfo(res);
      }
    },
    async checkPermission() {
      let res = await hasInsidePermission({
        type: "batch_order_agent"
      });
      let res2 = await hasInsidePermission({
        type: "patent_services"
      });
      let Account_List = res2.content.split(",").map(i => i.replace(/[^0-9]/gi, ""));
      this.setIsPatentService(Account_List.includes(this.common.getAgentInfo().account));
      // setIsPatentService
      let ID_List = res.content.split("#");
      this.setIsInside(ID_List.includes(this.common.getUserInfo().operatorId) || false);
    },
    async checkGrayscaleEnvironment() {
      // 判断是否灰度账号
      await hasInsidePermission({
        appKey: "quandashi4106565062",
        signMethod: "md5",
        inputType: 1,
        v: "1.0",
        executor: "140fcf1dfb9b6368d33e1d16d14fcad3",
        timestamp: new Date().getTime(),
        sign: new Date().getTime(),
        format: "json",
        type: "grayscale_phone"
      }).then(optRes => {
        if (optRes.code == 9091 && optRes.subCode == 10002) {
          if (
            optRes.data &&
            optRes.data.content &&
            optRes.data.content.indexOf(this.common.getAgentInfo().account) > -1
          ) {
            jsCookie.set("qds_version_id", "v2");
          } else {
            jsCookie.remove("qds_version_id");
          }
        }
      });
    },
    async loginByUserId() {
      try {
        let res = await loginByUserId({
          userId: this.common.getUserInfo().operatorId
        });
        this.setClientAccount(res);
      } catch {
        this.setClientAccount(false);
      }
    },
    async fnPermission() {
      if (!this.common.getUserInfo().userId) return;
      let res = await getListMemberPrivilege({
        userId: this.common.getUserInfo().userId
      });
      this.setPermissionList(res);
    },
    async queryCustomerLossCount() {
      let res = await getCustomerLossList({
        userId: this.common.getUserInfo().userId,
        pageNo: 1,
        pageSize: 20
      });
      this.setCustomerLossCount(res.total);
    }
  }
};
</script>

<style>
@import url("./assets/css/reset.css");
#app {
  width: 100%;
  height: 100%;
}
.pop-handle[x-placement^="bottom"] {
  padding: 0 !important;
}
.el-popover.person-info-card {
  padding: 0;
  border-radius: 8px;
}
.el-popover.my-popover {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
.el-popover.my-popover-des {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #999999;
}
.el-popover.person-info-card {
  padding: 0;
  border-radius: 8px;
}
</style>
