
<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="859px"
    append-to-body
  >
    <div class="content">
      <img
        src="@/assets/images/replaceOrder/close-icon.png"
        alt=""
        @click.stop="handleClose"
        class="closeIcon"
      />

      <img :src="codePay_img" alt="" class="codeImg" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "PartnershipWebTeamInviteDialog",

  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      codePay_img: ""
    };
  },

  async created() {
    this.codePay_img = await this.QrCode.toDataURL(
      process.env.VUE_APP_BASE_H5_API +
        `/hhr/login-invitation.html?userId=${this.common.getUserInfo().userId}&userName=${
          this.common.getUserInfo().userName
        }`,
      {
        width: 215,
        height: 215
      }
    );
  },

  methods: {
    handleClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    display: none;
  }
  .el-dialog {
    background-image: url(~@/assets/images/workBench/team-invite.png);
    background-size: 100% 100%;
    height: 500px;
    background-color: transparent;
    box-shadow: none;
  }
  .el-dialog__body {
    .content {
      img.closeIcon {
        width: 32px;
        position: absolute;
        bottom: -72px;
        left: 53%;
        cursor: pointer;
      }
      img.codeImg {
        position: absolute;
        top: 217px;
        left: 43.5%;
      }
    }
  }
}
</style>
