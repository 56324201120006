import Axios from "axios";
import { Message } from "element-ui";
import { Common } from "@/utils/common";
import jsCookie from "js-cookie";
function getTimestamp() {
  return Axios.request({
    baseURL: `${process.env.VUE_APP_BASE_API}`,
    url: "/common/getTimestamp",
    method: "post"
  });
}

const requestParams = {
  appKey: "quandashi4940841937",
  signMethod: "md5",
  executor: Common.getUserInfo().userId
};

const axios = Axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}`,
  timeout: 30000
});

axios.interceptors.request.use(
  async config => {
    let qds_version_id = jsCookie.get("qds_version_id");
    config.data = { ...requestParams, ...config.data };
    config.data.timestamp = (await getTimestamp()).data.data.timestamp;
    config.data.sign =
      new Date().getTime() + "" + Math.floor(Math.random() * 10000 + 1);
    config.headers["client"] = "v2";
    config.headers["qdsToken"] = Common.getUAA_TOKEN();
    if (qds_version_id && qds_version_id != "" && qds_version_id != undefined) {
      config.headers["qds_version_id"] = "v2";
    } else {
      config.headers["qds_version_id"] = "";
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => {
    // 跳过拦截 不提示异常但是抛出异常
    let notIntercept = [
      "/client/loginByUserId",
      "/account/queryBalanceCount",
      "/user/hhrInformation"
    ];
    if (res.data.code == 9091 && res.data.subCode == 10002) {
      return res.data;
    } else if (res.data.subCode == 10004) {
      return (window.location.href = `${process.env.VUE_APP_LOGIN_QDS}/?callback=${window.location.href}`);
    } else if (res.data.subCode == 10202) {
      return (window.location.href = process.env.VUE_APP_BASE_QDS);
    } else if (notIntercept.includes(res.config.url)) {
      return Promise.reject(new Error(res.data));
    } else {
      Message.error(res.data.subMessage || "系统错误,请稍后重试");
      return Promise.reject(new Error(res.data.subMessage));
    }
  },
  error => {
    if (error.message.includes("timeout")) {
      Message.error("网络超时,请稍后重试");
    }
    return Promise.reject(error);
  }
);

export default axios;
