import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
//帮助中心
import externalPageRoutes from "./external-page.js";
//工作台路由
import workbenchRoutes from "./workbench/index";
//工作中心路由
import workCenterRoutes from "./workCenter/index.js";
//常用工具路由
import toolsListRouters from "./toolsList/index";
//内部办公路由
import internalRouter from "./internalBusiness";
//会员中心
import membershipRoutes from "./membership";
//海星计划
import starfishRoute from "./starfish";
const routes = [
  {
    path: "/",
    name: "hhrRegister",
    component: home,
    redirect: "/partner-center",
    children: [
      //工作台
      ...workbenchRoutes,
      //内部办公
      ...internalRouter,
      //工作中心
      ...workCenterRoutes,
      // 常用工具只有商标监测 商标监测部分
      ...toolsListRouters,
      //会员中心
      ...membershipRoutes
    ]
  },
  {
    path: "/partner-inform",
    component: () => import("@/views/partnerInform/layout"),
    children: [
      {
        path: "/",
        name: "partnerInform",
        component: () => import("@/views/partnerInform")
      },
      {
        path: "submitting",
        name: "partnerInformSubmitting",
        component: () => import("@/views/partnerInform/submitting")
      }
    ]
  },
  {
    path: "/agreementFile",
    name: "agreementFile",
    component: () => import("@/views/partnerInform/agreementFile")
  },
  {
    path: "/rewardLayer",
    name: "rewardLayer",
    component: () => import("@/views/rewardLayer")
  },
  {
    path: "/identity-choose",
    name: "identityChoose",
    component: () => import("@/views/partnerInform/identityChoose")
  },
  //其他页面
  ...externalPageRoutes,
  //海星计划
  ...starfishRoute
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
