import monitorRouters from "./brand-monior.js";
import smartComposeRoutes from "./smart-compose.js";
import applicantsRoutes from "./applicants.js";
import assessRouters from "./brand-assess.js";
import customerClueRoutes from "./customer-clue.js";
const toolsListRouters = [
  ...monitorRouters,
  ...smartComposeRoutes,
  ...assessRouters,
  ...applicantsRoutes,
  ...customerClueRoutes
];
export default toolsListRouters;
