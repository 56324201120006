import axios from "@/utils/axios";
import { Message } from "element-ui";
import { uploadFile } from "./httpRequest";
import { getData } from "./index";
// 获取常用申请人列表
export function getApplicantList(params) {
  return getData(params, "common/applicantList", "post");
}

// 查询工商信息获取申请人列表
export function getBusinessApplicant(params) {
  return getData(params, "account/queryCompanyByKeyword", "post");
}

// 获取中国省市区
export function getRegion(params) {
  return getData(params, "common/queryRegionsMills", "post");
}
// 获取外国国家
export function getForeignCountry(params) {
  return getData(params, "order/queryBrandRegisterCountryList", "post");
}
// 获取常用联系人详情
export function getApplicantDetail(params) {
  return getData(params, "common/applicantDetail", "post");
}

// 编辑常用申请人
export function editApplicant(params) {
  return getData(params, "common/editApplicant", "post", "保存");
}
// 获取最近使用联系人
export function getRecentApplicant(params) {
  return getData(params, "common/nearApplicantList", "post");
}
// 下载委托书模板
export function downloadPowerAttorneyV2(params) {
  return getData(params, "upload/downloadPowerAttorneyV2", "post");
}
// 上传委托书模板
export function upGeneratePowerAttorneyV2(params) {
  return uploadFile(params, "upload/generatePowerAttorneyV2", "post");
}
// 上传文件
export function uploadFiles(params) {
  return uploadFile(params, "upload/upload", "post");
}
// 创建常用申请人
export function createApplicant(params) {
  return getData(params, "common/createApplicant", "post", "保存");
}
// 申请人黑名单
export function getQueryBlackList(params) {
  return getData(params, "common/queryBlackListApi", "post");
}
export function getOrderAgency(params) {
  return getData(params, "common/getOrderAgency", "post");
}
// 工商信息搜索
export function searchApplicantName(params) {
  return getData(params, "trademarkmonitor/searchApplicantName", "post");
}
// 工商信息列表
export function queryCompanyByKeyword(params) {
  return getData(params, "account/queryCompanyByKeyword", "post");
}
// 工商信息详情
export function queryCompanyInfo(params) {
  return getData(params, "account/queryCompanyInfo", "post");
}

//获取专属客服信息
export function getUniqueCustomerService(params) {
  return getData(params, "/crm-support/queryCustomerService", "post");
}

// 获取最近使用的申请人列表 带申请人审核状态
export function getNewApplicantList(params) {
  return getData(
    params,
    `${process.env.VUE_APP_BASE_QDS_API}uom/applicant/getLatestList`,
    "post"
  );
}

//根据关键字查询申请人列表
export function queryApplicantListByKey(params) {
  return getData(
    params,
    `${process.env.VUE_APP_BASE_QDS_API}uom/applicant/queryApplicantList`,
    "post"
  );
}

//新版注册 查询申请人详情
export function queryApplicantDetail(params) {
  return getData(
    params,
    `${process.env.VUE_APP_BASE_QDS_API}uom/applicant/detail`,
    "post"
  );
}
