import { Common } from "@/utils/common";
import {
  workbenchRoutes,
  customerClueHome,
  assessRouters,
  orderRoutes,
  smartComposeRoutes,
  applicantsRoutes,
  customerManageRoutes
} from "./migrate";
const qdsToken =
  Common.getCookie("UAA_TOKEN") &&
  JSON.parse(decodeURIComponent(Common.getCookie("UAA_TOKEN")));
const starfishRoute = [
  {
    path: "/starfish",
    component: () => import("@/views/starfishPlan/components/layout.vue"),
    children: [
      {
        path: "",
        redirect: "/starfish/index",
        meta: {
          hidden: true
        }
      },
      {
        path: "index",
        name: "partnerCenter2",
        component: () => import("@/views/starfishPlan/workBench/index.vue"),
        meta: {
          title: "工作台",
          icon: require("@/assets/images/starfish/workbench-icon.png"),
          isRoute: true,
          statistic: "工作台菜单-130002"
        }
      },
      ...customerManageRoutes,
      {
        path: "/",
        meta: {
          title: "客户",
          path: ["客户管理", "流失提醒"],
          icon: require("@/assets/images/starfish/customer-icon.png")
        },
        children: [
          {
            path: "customer-manage",
            name: "customerManage2",
            meta: {
              title: "客户管理",
              statistic: "客户管理-130047",
              isRoute: true
            }
          },
          {
            path: "customer-loss",
            name: "customerLoss2",
            meta: {
              title: "流失提醒",
              isRoute: true
            }
          }
        ]
      },
      {
        path: "brand-monitor-task",
        name: "brandMonitorTask2",
        component: () =>
          import("@/views/workCenter/brandMonitor/monitorTaskList.vue"),
        meta: {
          hidden: true,
          title: "监测",
          noSide: true
        }
      },
      {
        path: "brand-monitor-task-info",
        name: "brandMonitorTaskInfo2",
        component: () =>
          import("@/views/workCenter/brandMonitor/monitorTaskInfo.vue"),
        meta: {
          title: "商标监测任务详情",
          hidden: true,
          keepAlive: true,
          noSide: true
        }
      },
      ...smartComposeRoutes,
      ...orderRoutes,
      ...assessRouters,
      ...customerClueHome,
      //常用申请人
      ...applicantsRoutes,
      {
        path: "brand-monitor",
        name: "brandMonitor2",
        component: () =>
          import("@/views/workCenter/brandMonitor/monitorMsgList.vue"),
        meta: {
          hidden: true
        }
      },
      {
        path: "office-manage",
        name: "officeManage2",
        component: () =>
          import("@/views/workCenter/officeManage/officeManage.vue"),
        meta: {
          hidden: true,
          title: "订单官文"
        }
      },
      {
        path: "brand-manage",
        name: "brandManage2",
        component: () =>
          import("@/views/workCenter/brandManagement/detail.vue"),
        meta: {
          hidden: true,
          title: "订单详情"
        }
      },
      {
        path: "brand-manage-edit",
        name: "brandManageEdit2",
        component: () =>
          import("@/views/workCenter/brandManagement/pre-service.vue"),
        meta: {
          hidden: true,
          title: "订单编辑"
        }
      },
      {
        path: "apply-invoice",
        name: "applyInvoice2",
        component: () =>
          import("@/views/workCenter/applyInvoice/applyInvoice.vue"),
        meta: {
          hidden: true,
          title: "发票申请"
        }
      },
      {
        path: "mail-address",
        name: "mailAddress2",
        component: () => import("@/views/workCenter/mailAddress/index.vue"),
        meta: {
          title: "邮寄地址管理",
          hidden: true
        }
      },
      {
        path: "ahead-apply-invoice",
        name: "aheadApplyInvoice2",
        component: () =>
          import("@/views/workCenter/applyInvoice/aheadApplyInvoice.vue"),
        meta: {
          title: "提前申请开票",
          hidden: true
        }
      },
      {
        path: "order-assistant",
        name: "orderAssistant2",
        component: () => import("@/views/workCenter/orderAssistant/index.vue"),
        meta: {
          hidden: true,
          title: "订单助手"
        }
      },
      {
        path: "/",
        meta: {
          title: "订单",
          path: ["订单管理", "发票申请", "订单官文", "订单助手"],
          icon: require("@/assets/images/starfish/order-icon.png")
        },
        children: [
          {
            path: "order-manage",
            name: "orderManage",
            meta: {
              title: "订单管理",
              phpPath: "/partner-order/list?tab=noPay",
              statistic: "订单管理-130048"
            }
          },
          {
            path: "apply-invoice",
            name: "applyInvoice2",
            meta: {
              title: "发票申请",
              // phpPath: "/partner-invoice/not-apply",
              isRoute: true,
              statistic: "发票申请-130086"
            }
          },
          {
            path: "office-manage",
            name: "officeManage2",
            meta: {
              title: "订单官文",
              isRoute: true,
              statistic: "订单官文-130087"
            }
          },
          {
            path: "order-assistant",
            name: "orderAssistant2",
            meta: {
              title: "订单助手",
              isRoute: true
            }
          }
        ]
      },
      {
        path: "/",
        meta: {
          title: "获客工具",
          path: [
            "客户线索",
            "专利年费监测",
            "申请人监测",
            "商标监测",
            "代理机构监测",
            "监测动态消息",
            "企业分析报告"
          ],
          icon: require("@/assets/images/starfish/tools-icon.png")
        },
        children: [
          {
            path: "customer-clue-new",
            name: "customerClueNew2",
            meta: {
              title: "客户线索",
              statistic: "客户线索-130054",
              isRoute: true
            }
          },
          {
            path: "brand-monitor-task",
            name: "brandMonitorTask2",
            meta: {
              title: "专利年费监测",
              query: {
                activeName: "专利年费"
              },
              open: true,
              new: true
            }
          },
          {
            path: "brand-monitor-task",
            name: "brandMonitorTask2",
            meta: {
              title: "申请人监测",
              phpPath: `/new-brand-monitor?path=${process.env.VUE_APP_NEW_MONITOR}monitor/applicantList?tokenName=${qdsToken.tokenName}&tokenValue=${qdsToken.tokenValue}`,
              otherOrigin: true,
              statistic: "申请人监测-130063"
            }
          },
          {
            path: "brand-monitor-task",
            name: "brandMonitorTask2",
            meta: {
              title: "商标监测",
              phpPath: `/new-brand-monitor?path=${process.env.VUE_APP_NEW_MONITOR}monitor/brandnameList?tokenName=${qdsToken.tokenName}&tokenValue=${qdsToken.tokenValue}`,
              otherOrigin: true,
              statistic: "商标监测-130066"
            }
          },
          {
            path: "brand-monitor-task",
            name: "brandMonitorTask2",
            meta: {
              title: "代理机构监测",
              phpPath: `/new-brand-monitor?path=${process.env.VUE_APP_NEW_MONITOR}monitor/agencyList?tokenName=${qdsToken.tokenName}&tokenValue=${qdsToken.tokenValue}`,
              otherOrigin: true,
              statistic: "代理机构监测-130069"
            }
          },
          // {
          //   path: "brand-monitor",
          //   name: "brandMonitor2",
          //   meta: {
          //     title: "监测动态消息",
          //     statistic: "监测动态消息-130073",
          //     open: true
          //   }
          // },
          {
            path: "brand-monitor-task",
            name: "brandMonitorTask2",
            meta: {
              title: "企业分析报告",
              phpPath: "/analysis-report",
              otherOrigin: true,
              statistic: "企业分析报告-130071"
            }
          }
        ]
      },
      {
        path: "/",
        meta: {
          title: "查询工具",
          path: [
            "商标搜索(专业版)",
            "商标交易查询",
            "专利交易查询",
            "初审公告监测",
            "尼斯分类表"
          ],
          icon: require("@/assets/images/starfish/search-icon.png")
        },
        children: [
          {
            path: "",
            name: "",
            meta: {
              title: "商标搜索(专业版)",
              phpPath: `${process.env.VUE_APP_SEARCH_QDS}/silent?userIde=${
                Common.getUserInfo().userId
              }&source=2&callback=${
                process.env.VUE_APP_SEARCH_QDS
              }/brandSearchIndex`,
              otherOrigin: true,
              statistic: "商标搜索(专业版)-130052"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "商标交易查询",
              phpPath:
                process.env.VUE_APP_JY_QDS +
                `/home?qdsToken=${Common.getUAA_TOKEN()}`,
              otherOrigin: true,
              tokenFlag: true,
              statistic: "商标交易查询-130061"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "专利交易查询",
              phpPath:
                process.env.VUE_APP_ZL_QDS +
                `/homePage?qdsToken=${Common.getUAA_TOKEN()}`,
              otherOrigin: true,
              tokenFlag: true,
              statistic: "专利交易查询-130089"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "初审公告监测",
              phpPath: `${process.env.VUE_APP_SEARCH_QDS}/silent?userIde=${
                Common.getUserInfo().userId
              }&source=2&callback=${process.env.VUE_APP_GONGGAO_QDS}`,
              otherOrigin: true,
              statistic: "初审公告监测-130090"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "尼斯分类表",
              phpPath: `${process.env.VUE_APP_SEARCH_QDS}/silent?userIde=${
                Common.getUserInfo().userId
              }&source=2&callback=${
                process.env.VUE_APP_SEARCH_QDS
              }/goodsClassify`,
              otherOrigin: true,
              statistic: "尼斯分类表-130091"
            }
          }
        ]
      },
      {
        path: "/",
        meta: {
          title: "服务工具",
          path: ["智能撰写", "商标估价", "商标起名", "常用申请人"],
          icon: require("@/assets/images/starfish/service-icon.png")
        },
        children: [
          {
            path: "smart-compose",
            name: "smartCompose2",
            meta: {
              title: "智能撰写",
              isRoute: true,
              statistic: "智能撰写-130058"
            }
          },
          {
            path: "brand-assess",
            name: "brandAssess2",
            meta: {
              title: "商标估价",
              isRoute: true,
              statistic: "商标估价-130102",
              new: true
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "商标起名",
              phpPath: process.env.VUE_APP_QIMING,
              otherOrigin: true,
              statistic: "商标起名-130077"
            }
          },
          {
            path: "applicants-new",
            name: "applicantsNew2",
            meta: {
              title: "常用申请人",
              isRoute: true
            }
          }
        ]
      },
      {
        path: "/",
        meta: {
          title: "自助递交",
          path: ["自助下单", "自助订单", "递交管理", "机构官文", "账户设置"],
          icon: require("@/assets/images/starfish/self-help-icon.png")
        },
        children: [
          {
            path: "",
            name: "",
            meta: {
              title: "自助下单",
              phpPath: process.env.VUE_APP_QIANJIAN + "order/index",
              judgment: true,
              statistic: "自助下单-130092"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "自助订单",
              phpPath: process.env.VUE_APP_QIANJIAN + "order/list",
              judgment: true,
              statistic: "自助订单-130093"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "递交管理",
              phpPath: process.env.VUE_APP_QIANJIAN + "submit/index",
              judgment: true,
              statistic: "递交管理-130095"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "机构官文",
              phpPath: process.env.VUE_APP_QIANJIAN + "official-file/list",
              judgment: true,
              statistic: "机构官文-130094"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "账户设置",
              phpPath: process.env.VUE_APP_QIANJIAN + "account/index",
              judgment: true,
              statistic: "账户设置-130096"
            }
          }
        ]
      },
      {
        path: "export-record",
        name: "exportRecord2",
        component: () =>
          import("@/views/workCenter/exportRecord/exportRecord.vue"),
        meta: {
          title: "导出记录",
          icon: require("@/assets/images/starfish/export-icon.png"),
          isRoute: true,
          statistic: "导出记录-130097"
        }
      },
      {
        path: "/",
        meta: {
          title: "知产课堂",
          path: [
            "新手教程",
            "基本知识",
            "法规与实务",
            "AI工具赋能",
            "常见问题"
          ],
          icon: require("@/assets/images/starfish/news-icon.png")
        },
        children: [
          {
            path: "",
            name: "",
            meta: {
              title: "新手教程",
              phpPath: `/help/index?url=${window.location.href}&id=8732`,
              statistic: "新手教程-130098"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "基本知识",
              phpPath: `/help/index?url=${window.location.href}&id=33`,
              statistic: "基本知识-130099"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "法规与实务",
              phpPath: `/help/index?url=${window.location.href}&id=34`,
              statistic: "法规与实务-130100"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "AI工具赋能",
              phpPath: `/help/index?url=${window.location.href}&id=35`,
              statistic: "AI工具赋能-140101"
            }
          },
          {
            path: "",
            name: "",
            meta: {
              title: "常见问题",
              phpPath: `/help/index?url=${window.location.href}&id=36`,
              statistic: "常见问题-140102"
            }
          }
        ]
      },
      {
        path: "membership-center",
        name: "membershipCenter2",
        component: () => import("@/views/membership"),
        meta: {
          title: "会员中心",
          hidden: true
        }
      },
      {
        path: "membership-categories",
        name: "membershipCategories2",
        component: () => import("@/views/membership/membershipCategories.vue"),
        meta: {
          title: "会员方案",
          hidden: true,
          noSide: true
        }
      },
      {
        path: "membership-consumption",
        name: "membershipConsumption2",
        component: () => import("@/views/membership/consumption.vue"),
        meta: {
          title: "会员中心充值消耗明细",
          hidden: true
        }
      },

      {
        path: "membership-record",
        name: "membershipRecord2",
        component: () => import("@/views/membership/record.vue"),
        meta: {
          title: "会员开通记录",
          hidden: true
        }
      },
      {
        path: "user-message",
        name: "userMessage2",
        component: () => import("@/views/userMessage/index.vue"),
        meta: {
          title: "消息通知",
          hidden: true
        }
      },
      {
        path: "personal-inform",
        name: "personalInform2",
        component: () => import("@/views/personalInform/index.vue"),
        meta: {
          title: "个人信息",
          hidden: true
        }
      },
      {
        path: "product-package",
        name: "productPackage2",
        component: () => import("@/views/productPackage/index.vue"),
        meta: {
          title: "产品包首页",
          hidden: true,
          noSide: true
        }
      },
      {
        path: "package-service-voucher",
        name: "serviceVoucher2",
        component: () => import("@/views/productPackage/serviceVoucher.vue"),
        meta: {
          title: "我的服务券",
          hidden: true
        }
      },
      //工作台我的资产 收益 header内菜单
      ...workbenchRoutes
    ]
  }
];

export default starfishRoute;
export { orderRoutes };
