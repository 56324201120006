<template>
  <div class="container" :class="{ 'no-side': noSide }">
    <!-- 头部导航栏 -->
    <div id="qds-header">
      <Header :username="username" :headUrl="headUrl" :bellNum="bellNum"></Header>
    </div>
    <div id="hhr-bodyer" class="clear">
      <!-- 左侧边栏 -->
      <LeftAside></LeftAside>
      <!-- 右侧主体部分 -->
      <div id="hhr-rightContainer" class="globalScrool">
        <!-- <register-header></register-header>
        <div class="register-title">
          <h3>商标注册下单</h3>
          <div class="title-line"></div>
          <customer-inform @saveProxy="saveProxy"></customer-inform>
        </div>

        <brand-register
          @saveBrandOrder="saveBrandOrder"
          :applicantProxyOrder2="applicantProxyOrder2"
        ></brand-register>
        <order-footer :saveBrandList="saveBrandList" v-if="refresFlag"></order-footer> -->
        <!-- <component :is="currentComponent"></component> -->

        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/header.vue";
import LeftAside from "@/components/left_aside.vue";
import { getInnerMsgCount, permission } from "@/api/header";
// import brandRegister from "./brandRegister/home.vue";
export default {
  name: "hhrRegister",
  components: {
    Header,
    LeftAside
    // brandRegister
  },
  data() {
    return {
      // 用户头像
      headUrl: "",
      // 用户名
      username: "",
      // 头部导航栏消息数量
      bellNum: 0,
      userId: "",
      currentComponent: "brandRegister",
      noSide: false
    };
  },
  watch: {
    "$route.meta": {
      handler(n) {
        if (n) {
          this.noSide = n.noSide ? n.noSide : false;
        }
      },
      immediate: true
    }
  },
  async created() {
    let userInfo = this.common.getUserInfo();
    if (userInfo) {
      this.userId = userInfo.userId;
      this.username = userInfo.userName;
      this.headUrl = userInfo.userImg ? `${process.env.VUE_APP_BASE_FILE}${userInfo.userImg}` : "";
    }
    this.getInnerMsgCount();
    await this.permission();
  },
  methods: {
    ...mapActions(["setIsShow"]),
    async permission() {
      try {
        const params = {
          agentIde: this.common.getAgentInfo().agentIde
        };
        const res = await permission(params);
        this.setIsShow(res.show);
      } catch (error) {
        console.log(error);
      }
    },

    // 获取头部导航栏未读消息数
    async getInnerMsgCount() {
      const params = {
        userId: this.userId
      };
      const res = await getInnerMsgCount(params);
      this.bellNum = res.manaCount + res.qzdCount + res.sysCount;
    },

    saveProxy(val) {
      this.applicantProxyOrder2 = val;
      console.log("applicantProxyOrder2", this.applicantProxyOrder2);
    } // 申请人信息
  }
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #f5f5f5;
  width: 100%;
  #qds-header {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-bottom: solid 1px rgba(113, 114, 119, 0.1);
    box-shadow: 0 4px 6px 0 rgba(113, 114, 119, 10%);
    padding: 0;
  }
  #hhr-bodyer {
    width: 1240px;
    // height: calc(100vh - 140px);
    overflow: auto;
    margin: auto;
    padding: 18px 0;
    display: flex;
    box-sizing: border-box;
    padding-bottom: 100px;
  }
  #hhr-rightContainer {
    flex: 1;
    margin-left: 15px;
    // overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 80px);
  }
}
.container::after {
  content: "";
  clear: both;
  display: block;
  height: 0;
}
.container.no-side {
  #hhr-leftNav {
    display: none;
  }
  #hhr-rightContainer {
    margin-left: 0px;
  }
}
.globalScrool::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}
.globalScrool::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #e4e4e4;
}
.globalScrool::-webkit-scrollbar-track {
  background: transparent;
}
</style>
