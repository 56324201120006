import { getData } from "./index";
import axios from "axios";
//流失客户列表
export function getCustomerLossList(params) {
  return getData(params, "legend4/lossCustomerManage/listLossCustomer", "post");
}

//客户所有商标
export function getBrandListByCustomer(params) {
  return getData(
    params,
    "legend4/lossCustomerManage/listBrandWithCustomer",
    "post"
  );
}

//客户业务动态
export function getListHhrBrandBusiness(params) {
  return getData(
    params,
    "legend4/lossCustomerManage/listHhrBrandBusinessDynamics",
    "post"
  );
}

//更改流失客户类型 关注 是否沟通
export function updateLossCustomerType(params) {
  return getData(
    params,
    "legend4/lossCustomerManage/updateLossCustomerType",
    "post"
  );
}

//更加商标号查询esID
export function brandGetDetailIdByTmId(params) {
  return getData(params, "brand/brandGetDetailIdByTmId", "post");
}

//批量忽略
export function batchIgnoreLoss(params) {
  return getData(params, "legend4/lossCustomerManage/batchIgnore", "post");
}
/**
 * 以下为MOCK接口
 * @param {*} params MOCK
 * @returns
 */
// export function getCustomerLossList(params) {
//   return getMockData(
//     params,
//     "http://139.9.118.127:3001/mock/11/lossCustomerManage/listLossCustomer",
//     "post"
//   );
// }

// export function getBrandListByCustomer(params) {
//   return getMockData(
//     params,
//     "http://139.9.118.127:3001/mock/11/lossCustomerManage/listBrandWithCustomer",
//     "post"
//   );
// }
// export function getListHhrBrandBusiness(params) {
//   return getMockData(
//     params,
//     "http://139.9.118.127:3001/mock/11/lossCustomerManage/listHhrBrandBusinessDynamics",
//     "post"
//   );
// }

// export function updateLossCustomerType(params) {
//   return getMockData(
//     params,
//     "http://139.9.118.127:3001/mock/11/lossCustomerManage/updateLossCustomerType",
//     "post"
//   );
// }

// MOCK接口: MOCK数据 不拦截直接返回 接口联调后记着删除
const getMockData = async (data, url, method) => {
  let res = await axios.request({
    url,
    method,
    data
  });
  return res.data.data;
};
