const smartOrderRoutes = [
  {
    path: "/smart-order",
    name: "smartOrder",
    component: () => import("@/views/workCenter/smartOrder/index.vue"),
    meta: {
      title: "智能下单",
      keepAlive: true
    }
  },
  {
    path: "/brandChange-detail",
    name: "brandChangeDetail",
    component: () =>
      import("@/views/workCenter/smartOrder/components/brandChangeDetail.vue"),
    meta: {
      title: "智能商标变更订单详情",
      keepAlive: true
    }
  },
  {
    path: "/brandTransfer-detail",
    name: "brandTransferDetail",
    component: () =>
      import(
        "@/views/workCenter/smartOrder/components/brandTransferDetail.vue"
      ),
    meta: {
      title: "智能商标转让订单详情",
      keepAlive: true
    }
  },
  {
    path: "/brandContinue-detail",
    name: "brandContinueDetail",
    component: () =>
      import(
        "@/views/workCenter/smartOrder/components/brandContinueDetail.vue"
      ),
    meta: {
      title: "智能商标续宽订单详情",
      keepAlive: true
    }
  },
  {
    path: "/brandRevoke-detail",
    name: "brandRevokeDetail",
    component: () =>
      import("@/views/workCenter/smartOrder/components/brandRevokeDetail.vue"),
    meta: {
      title: "智能商标撤三订单详情",
      keepAlive: true
    }
  }
];

export default smartOrderRoutes;
