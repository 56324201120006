import axios from "@/utils/axios";
import { Message } from "element-ui";

// 获取头部导航栏未读消息数
export function getInnerMsgCount(params) {
  return getData(params, "common/getInnerMsgCount", "post");
}
// 权限
export function permission(params) {
  return getData(params, "/order/inside", "post");
}

//mock登录
export function getCookies(params) {
  return getData(params, "user/tuser/login", "post");
}

export function hasInsidePermission(params) {
  return getData(params, "legend4/common/queryConstantOptimizeInfo", "post");
}

import { getData } from "./index";

//机构登录判断
export function loginByUserId(params) {
  return clientLogin(params, "/client/loginByUserId", "post");
}

export async function clientLogin(data, url, methods, tips) {
  let res = await axios({
    url,
    method: methods,
    data
  });
  if (res.code == "ok" || res.subCode == 10002) {
    if (tips) {
      Message.success(`${tips}成功！`);
    }
    return true;
  } else if (res.subCode != 10002) {
    return false;
  }
}
