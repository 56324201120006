/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num, hasDecimal, divide) {
  if (hasDecimal) {
    if (divide) {
      num =
        (num && parseFloat(num / 100).toFixed(hasDecimal)) ||
        parseFloat(0).toFixed(hasDecimal);
    } else {
      num =
        (num && parseFloat(num).toFixed(hasDecimal)) ||
        parseFloat(0).toFixed(hasDecimal);
    }
  }
  return (
    (num &&
      num
        .toString()
        .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))) ||
    parseFloat(0).toFixed(hasDecimal)
  );
}
/**appType
 * APP著作权人类型
 * @param {*} val
 */
export function legalPersonType(val) {
  let name = "";
  if (val == 1) {
    name = "自然人";
  } else if (val == 21) {
    name = "企业法人";
  } else if (val == 22) {
    name = "机关法人";
  } else if (val == 23) {
    name = "事业单位法人";
  } else if (val == 24) {
    name = "社会团体法人";
  } else if (val == 3) {
    name = "其他组织";
  } else if (val == 4) {
    name = "其他";
  } else {
    name = "";
  }
  return name;
}
/**certificateType
 * 证件类型
 * @param {*} val
 */
export function certificateType(val) {
  let name = "";
  if (val == "1") {
    name = "身份证";
  } else if (val == "12") {
    name = "统一社会信用代码";
  } else if (val == "14") {
    name = "其他";
  } else if (val == "4") {
    name = "护照";
  } else if (val == "9") {
    name = "其他有效证件";
  } else if (val == "21") {
    name = "户口本";
  } else if (val == "15") {
    name = "台湾居民来往大陆通行证";
  } else if (val == "26") {
    name = "台湾居民居住证";
  } else if (val == "20") {
    name = "居然身份证明（军/警官证、士兵证等）";
  }
  return name;
}
/**
 * 获取著作权人状态
 * @param {*} val
 */
export function getCopyrightStatus(val) {
  let name = "";
  if (val == 0) {
    name = "待认证";
  } else if (val == 1) {
    name = "已认证";
  } else if (val == 2) {
    name = "认证驳回";
  } else {
    name = "";
  }
  return name;
}
/**rightPart
 * 开发方式
 * @param {*} val
 */
export function devWay(val) {
  let name = "";
  if (val == "1") {
    name = "独立开发";
  } else if (val == "2") {
    name = "合作开发";
  } else if (val == "3") {
    name = "委托开发";
  } else if (val == "4") {
    name = "下达任务开发";
  }
  return name;
}
/**
 * APP软件分类
 * @param {*} val
 */
export function appType(val) {
  let type1 = "";
  let type2 = "";
  let arr = [];
  if (!checkEmpty(val)) {
    arr = val.split(",");
    if (arr[0] == 0) {
      type1 = "应用";
    } else if (arr[0] == 1) {
      type1 = "游戏";
    }
    if (arr[1] == 10) {
      type2 = "生活实用";
    } else if (arr[1] == 11) {
      type2 = "资讯阅读";
    } else if (arr[1] == 12) {
      type2 = "办公用品";
    } else if (arr[1] == 25) {
      type2 = "其他";
    } else if (arr[1] == 27) {
      type2 = "购物";
    } else if (arr[1] == 28) {
      type2 = "教育教学";
    } else if (arr[1] == 3) {
      type2 = "社交通讯";
    } else if (arr[1] == 4) {
      type2 = "系统工具";
    } else if (arr[1] == 5) {
      type2 = "理财";
    } else if (arr[1] == 6) {
      type2 = "主题壁纸";
    } else if (arr[1] == 7) {
      type2 = "旅游出行";
    } else if (arr[1] == 8) {
      type2 = "影音播放";
    } else if (arr[1] == 9) {
      type2 = "拍摄美化";
    } else if (arr[1] == 13) {
      type2 = "休闲益智";
    } else if (arr[1] == 14) {
      type2 = "赛车竞速";
    } else if (arr[1] == 15) {
      type2 = "棋牌桌游";
    } else if (arr[1] == 16) {
      type2 = "角色扮演";
    } else if (arr[1] == 17) {
      type2 = "模拟辅助";
    } else if (arr[1] == 18) {
      type2 = "动作射击";
    } else if (arr[1] == 19) {
      type2 = "网络游戏";
    } else if (arr[1] == 20) {
      type2 = "经营养成";
    } else if (arr[1] == 21) {
      type2 = "体育竞技";
    } else if (arr[1] == 26) {
      type2 = "其他";
    }
  }
  return type1 + "-" + type2;
}
/***
 * 联系人类别
 * 0:订单联系人，1 下单人，2 代理人,3 撰写人，4 审核人, 5 递交人
 */
export function contactType(type) {
  let typeCn = "";
  if (type == 0) {
    typeCn = "订单联系人";
  } else if (type == 1) {
    typeCn = "下单人";
  } else if (type == 2) {
    typeCn = "代理人";
  } else if (type == 3) {
    typeCn = "撰写人";
  } else if (type == 4) {
    typeCn = "审核人";
  } else if (type == 5) {
    typeCn = "递交人";
  } else {
    typeCn = "-";
  }
  return typeCn;
}
/***
 * 联系人类别
 * 0:订单联系人，1 下单人，2 代理人,3 撰写人，4 审核人, 5 递交人
 */
export function customerType(type) {
  let typeCn = "";
  if (type == 1) {
    typeCn = "下单人";
  } else if (type == 2) {
    typeCn = "订单联系人";
  } else if (type == 3) {
    typeCn = "订单维护人";
  } else if (type == 4) {
    typeCn = "订单代理人";
  } else if (type == 5) {
    typeCn = "流程审核人";
  } else if (type == 6) {
    typeCn = "合伙人";
  } else {
    typeCn = "-";
  }
  return typeCn;
}
/**
 * 支付类型
 */
export function payType(data) {
  let name = "";
  if (data == 0) {
    name = "无支付方式";
  } else if (data == 1) {
    name = "微信支付";
  } else if (data == 2) {
    name = "支付宝支付";
  } else if (data == 3) {
    name = "百度支付";
  } else if (data == 4) {
    name = "个人银联支付";
  } else if (data == 5) {
    name = "企业银联支付";
  } else if (data == 6) {
    name = "线下支付";
  } else if (data == 7) {
    name = "虚拟支付";
  } else if (data == 8) {
    name = "权豆支付";
  } else if (data == 9) {
    name = "余额支付";
  } else if (data == 10) {
    name = "服务券支付";
  } else {
    name = "--";
  }
  return name;
}
/**
 * 业务类型
 */
export function businessType(data) {
  let name = "";
  if (data == 1) {
    name = "商标";
  } else if (data == 2) {
    name = "专利";
  } else if (data == 3) {
    name = "版权";
  } else if (data == 4) {
    name = "企业服务";
  } else if (data == 5) {
    name = "其他";
  } else {
    name = "--";
  }
  return name;
}
/**
 * 作品性质
 * @param {*} val
 */
export function opusInditeType(val) {
  let name = "";
  if (val == "ORIGINAL") {
    name = "原创";
  } else if (val == "ADAPTED") {
    name = "改编";
  } else if (val == "TRANSLATED") {
    name = "翻译";
  } else if (val == "COMPILED") {
    name = "汇编";
  } else if (val == "ANNOTATION") {
    name = "注释";
  } else if (val == "COLLATING") {
    name = "整理";
  } else if (val == "OTHER") {
    name = "其他";
  } else {
    name = "";
  }
  return name;
}
/**
 * 权利取得方式
 * @param {*} val
 */
export function obtainType(val) {
  let name = "";
  if (val == "ORIGINAL") {
    name = "原始";
  } else if (val == "INHERIT") {
    name = "继承";
  } else if (val == "REORGANIZATION") {
    name = "承受";
  } else if (val == "TRANSFER") {
    name = "转让";
  } else if (val == "OTHER") {
    name = "其他";
  } else {
    name = "";
  }
  return name;
}
/**rightPart
 * 权利归属方式
 * @param {*} val
 */
export function rightOwnType(val) {
  let name = "";
  if (val == "POT") {
    name = "个人作品";
  } else if (val == "COT") {
    name = "合作作品";
  } else if (val == "LOT") {
    name = "法人作品";
  } else if (val == "JOT") {
    name = "职务作品";
  } else if (val == "DOT") {
    name = "委托作品";
  } else {
    name = "";
  }
  return name;
}
/**
 * 权利归属方式
 * @param {*} val
 */
export function rightPart(val) {
  let name = "";
  if (val != "" && val != null && val != undefined) {
    let list = val.split(",");
    list.forEach(item => {
      if (item == 1) {
        name = name + "发表权" + " ";
      } else if (item == 2) {
        name = name + "署名权" + " ";
      } else if (item == 3) {
        name = name + "修改权" + " ";
      } else if (item == 4) {
        name = name + "保护作品完整权";
      } else if (item == 5) {
        name = name + "复制权" + " ";
      } else if (item == 6) {
        name = name + "发行权" + " ";
      } else if (item == 7) {
        name = name + "出租权" + " ";
      } else if (item == 8) {
        name = name + "展览权" + " ";
      } else if (item == 9) {
        name = name + "表演权" + " ";
      } else if (item == 10) {
        name = name + "放映权" + " ";
      } else if (item == 11) {
        name = name + "广播权" + " ";
      } else if (item == 12) {
        name = name + "信息网络传播权" + " ";
      } else if (item == 13) {
        name = name + "摄制权" + " ";
      } else if (item == 14) {
        name = name + "改编权" + " ";
      } else if (item == 15) {
        name = name + "翻译权" + " ";
      } else if (item == 16) {
        name = name + "汇编权" + " ";
      } else if (item == 17) {
        name = name + "其他" + " ";
      } else {
        name = "";
      }
    });
  }
  return name;
}
/**rightPart
 * 权利归属方式
 * @param {*} val
 */
export function medium(val) {
  let name = "";
  if (val == "DZ") {
    name = "电子";
  } else if (val == "CD") {
    name = "光盘";
  } else if (val == "A4") {
    name = "A4纸";
  } else {
    name = "";
  }
  return name;
}
/*  判断值是否为空  */
function checkEmpty(val) {
  if (val === undefined || val === null || (val === "" && val !== 0)) {
    return true;
  }
  return false;
}
/**
 * 数字格式为标准金额展示方式   ' ，'隔开
 */
export function format_number2Cash(nStr) {
  nStr += ""; //数字转换为字符
  let x = nStr.split("."); //按照小数点分隔
  let x1 = x[0]; //整数部分
  let x2 = x.length > 1 ? "." + x[1] : ""; //小数部分
  let rgx = /(\d+)(\d{3})/; //正则式定义
  while (rgx.test(x1)) {
    //正则式匹配
    x1 = x1.replace(rgx, "$1" + "," + "$2"); //正则式替换
  }
  return x1 + x2;
}
