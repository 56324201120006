import { getData } from "./index";

//获取会员信息
export function queryMembershipInfo(params) {
  return getData(params, "/legend4/saasmember/queryMemberInfo", "post");
}

//获取会员资产
export function getMembershipAssets(params) {
  return getData(params, "/legend4/saasmember/myResource", "post");
}

//会员开通记录
export function queryMembershipListRecord(params) {
  return getData(params, "/legend4/saasmember/listMemberRecord", "post");
}

//会员充值消耗明细
export function queryMembershipListConsumption(params) {
  return getData(params, "/legend4/saasmember/listSaasDepositDetail", "post");
}

//是否有当前模块权限
export function checkIsAccessVIP(params) {
  return getData(params, "/legend4/saasmember/isMemberAccess", "post");
}

//会员类型列表
export function listMemberType(params) {
  return getData(params, `/legend4/saasmember/listMemberType`, "post");
}

//查询是否是会员
export function isSaasMember(params) {
  return getData(params, "/legend4/saasmember/isSaasMember", "post");
}

//业务服务列表
export function listBenefits(params) {
  return getData(params, "/legend4/saasmember/listBenefits", "post");
}

//海星计划页面统计点击
export function ipStatistic(params) {
  return getData(params, "/legend4/ipStatistics", "post");
}

//获取所有页面功能权限
export function getListMemberPrivilege(params) {
  return getData(params, "/legend4/saasmember/listMemberPrivilege", "post");
}

//普通合伙人获取推荐海星会员信息
export function getRecommendMemberInfo(params) {
  return getData(params, "/legend4/saasmemberv2/suggestMemberInfo", "post");
}

//查看所有推荐会员信息
export function queryListOtherMemberInfo(params) {
  return getData(params, "/legend4/saasmemberv2/listOtherMemberInfo", "post");
}

//创建会员订单
export function createMemberOrder(params) {
  return getData(params, "/legend4/saasmember/createMemberOrder");
}

//查询saas会员订单支付信息
export function queryMemberOrderInfo(params) {
  return getData(params, "/legend4/saasmember/queryMemberOrderInfo");
}

//获取专属客服
export function queryCurrentUser(params) {
  return getData(params, "/legend4/agentcustomer/queryCurrentUser", "post");
}

//获取公众号地址
export function queryQrCodeUrl(params) {
  return getData(params, "/legend4/wechat/getQrCodeUrl", "post");
}
