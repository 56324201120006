import { Common } from "./common";
const qdsToken =
  Common.getCookie("UAA_TOKEN") &&
  JSON.parse(decodeURIComponent(Common.getCookie("UAA_TOKEN")));
const routeBaseUrl = process.env.VUE_APP_BASE_QDS;
// 内部办公
const insideList = [
  {
    name: "业务下单",
    hrefName: `/agent/all-service/index`,
    isSelected: false
  },
  {
    name: "订单作业台",
    hrefName: `/agent/order/not-pay`,
    isSelected: false
  },
  {
    name: "渠道下单记录",
    hrefName: `/channel/order-list`,
    isSelected: false
  },
  {
    name: "代开发票",
    hrefName: `/agent/agent-invoice/not-apply`,
    isSelected: false
  },
  {
    name: "常用申请人",
    hrefName: `/agent/applicants/list`,
    isSelected: false
  },
  {
    name: "腾讯申请人",
    hrefName: `/agent/tencent/list`,
    isSelected: false
  }
];
// 工作中心
const serverList = [
  {
    name: "商标注册",
    hrefName: "/brand-register",
    isSelected: true
  },
  {
    name: "业务下单",
    hrefName: `/package-order`,
    isSelected: false
  },
  // {
  //   name: "智能下单",
  //   hrefName: `/smart-order`,
  //   isSelected: false
  // },
  {
    name: "订单管理",
    hrefName: `/partner-order/list?tab=noPay`,
    isSelected: false
  },
  {
    name: "发票申请",
    hrefName: `/apply-invoice`,
    // hrefName: `/partner-invoice/not-apply`,
    isSelected: false
  },
  {
    name: "官文管理",
    hrefName: `/office-manage`,
    // hrefName: `/partner-send/attachment-all`,
    isSelected: false
  },
  {
    name: "订单助手",
    hrefName: `/order-assistant`,
    isSelected: false
  },
  {
    name: "客户管理",
    hrefName: `/customer-manage`,
    // hrefName: `/partner/customer-list`,
    isSelected: false,
    hasPermission: true,
    statistic: "普通合伙人客户管理菜单-150027"
  },
  {
    name: "流失提醒",
    hrefName: "/customer-loss",
    isSelected: false,
    statistic: "普通合伙人流失提醒菜单-150028"
  },
  {
    name: "导出记录",
    hrefName: "/export-record",
    // hrefName: `/agent/export-record/index`,
    isSelected: false
  },
  {
    name: "邮寄地址管理",
    hrefName: "/mail-address",
    isSelected: false
  }
];
// 常用工具
const toolList = [
  {
    name: "商标搜索(专业版)",
    hrefName: `${process.env.VUE_APP_SEARCH_QDS}/silent?userIde=${
      Common.getUserInfo().userId
    }&source=2&callback=${process.env.VUE_APP_SEARCH_QDS}/brandSearchIndex`,
    new: false,
    isSelected: false,
    hasPermission: true,
    statistic: "普通合伙人商标搜索菜单-150029"
  },
  {
    name: "商标估价",
    hrefName: `/brand-assess`,
    new: false,
    isSelected: false,
    hasPermission: true,
    statistic: "普通合伙人商标估价菜单-150030"
  },
  // {
  //   name: "智能撰写",
  //   hrefName: `/smart-compose`,
  //   new: true,
  //   isSelected: false
  // },
  {
    name: "客户线索",
    hrefName: `/customer-clue-new`,
    new: true,
    isSelected: false,
    statistic: "普通合伙人客户线索菜单-150031"
  },
  {
    name: "专利年费监测",
    hrefName: `/brand-monitor-task?activeName=专利年费`,
    new: false,
    isSelected: false,
    monitorPermission: true,
    statistic: "普通合伙人专利年费监测菜单-150032"
  },
  // {
  //   name: "监测动态消息",
  //   hrefName: `/brand-monitor`,
  //   new: false,
  //   isSelected: false,
  //   monitorPermission: true
  // },
  {
    name: "代理机构监测",
    hrefName: `/new-brand-monitor?path=${process.env.VUE_APP_NEW_MONITOR}monitor/agencyList?tokenName=${qdsToken.tokenName}&tokenValue=${qdsToken.tokenValue}`,
    new: false,
    isSelected: false,
    monitorPermission: true,
    statistic: "普通合伙人代理机构监测菜单-150033"
  },
  {
    name: "申请人监测",
    hrefName: `/new-brand-monitor?path=${process.env.VUE_APP_NEW_MONITOR}monitor/applicantList?tokenName=${qdsToken.tokenName}&tokenValue=${qdsToken.tokenValue}`,
    new: false,
    isSelected: false,
    monitorPermission: true,
    statistic: "普通合伙人申请人监测菜单-150034"
  },

  {
    name: "商标交易查询",
    hrefName: `${
      process.env.VUE_APP_JY_QDS
    }/home?qdsToken=${Common.getUAA_TOKEN()}`,
    new: false,
    isSelected: false,
    statistic: "普通合伙人商标交易查询菜单-150035"
  },
  {
    name: "专利交易查询",
    hrefName: `${
      process.env.VUE_APP_ZL_QDS
    }/homePage?qdsToken=${Common.getUAA_TOKEN()}`,
    new: true,
    isSelected: false,
    statistic: "普通合伙人专利交易查询菜单-150036"
  },
  {
    name: "企业分析报告",
    hrefName: `/analysis-report`,
    new: false,
    isSelected: false,
    monitorPermission: true,
    statistic: "普通合伙人企业分析报告菜单-150037"
  },
  // {
  //   name: "企业分析报告",
  //   hrefName: `${routeBaseUrl}/monitor-brand/index?tabType=1&showPdf=1`,
  //   new: false,
  //   isSelected: false
  // },
  {
    name: "商标起名",
    hrefName: `${process.env.VUE_APP_QIMING}`,
    new: false,
    isSelected: false,
    hasPermission: true,
    statistic: "普通合伙人商标起名菜单-150038"
  },
  {
    name: "初审公告监测",
    hrefName: `${process.env.VUE_APP_SEARCH_QDS}/silent?userIde=${
      Common.getUserInfo().userId
    }&source=2&callback=${process.env.VUE_APP_GONGGAO_QDS}`,
    new: false,
    isSelected: false,
    statistic: "普通合伙人初审公告监测菜单-150039"
  },
  {
    name: "尼斯分类表",
    hrefName: `${process.env.VUE_APP_SEARCH_QDS}/silent?userIde=${
      Common.getUserInfo().userId
    }&source=2&callback=${process.env.VUE_APP_SEARCH_QDS}/goodsClassify`,
    new: false,
    isSelected: false,
    statistic: "普通合伙人尼斯分类表菜单-150040"
  },
  {
    name: "常用申请人",
    hrefName: `/applicants-new`,
    new: false,
    isSelected: false
  }
];
// 知产资讯  地址参数 文章ID
const newsList = [
  {
    name: "新手教程",
    hrefName: `${routeBaseUrl}/help/index?url=${window.location.href}&id=8732`,
    isSelected: false
  },
  {
    name: "基本知识",
    hrefName: `${routeBaseUrl}/help/index?url=${window.location.href}&id=33`,
    isSelected: false
  },
  {
    name: "法规与实务",
    hrefName: `${routeBaseUrl}/help/index?url=${window.location.href}&id=34`,
    isSelected: false
  },
  {
    name: "AI工具赋能",
    hrefName: `${routeBaseUrl}/help/index?url=${window.location.href}&id=35`,
    isSelected: false
  },
  {
    name: "常见问题",
    hrefName: `${routeBaseUrl}/help/index?url=${window.location.href}&id=36`,
    isSelected: false
  }
];

export { routeBaseUrl, insideList, toolList, serverList, newsList };
