import "babel-polyfill";
import Vue from "vue";
import App from "./index.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
// import * as pdfJs from "pdfjs-dist";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// pdfJs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import "element-ui/lib/theme-chalk/index.css";
import lodash from "lodash";
import QrCode from "qrcode";
import "../theme/index.css";
import * as filters from "./filters";
import dayjs from "dayjs";
import { Common } from "./utils/common";
import { getCookies } from "@/api/header";
import jsCookie from "js-cookie";
import JSEncrypt from "jsencrypt";
import Toast from "@/utils/toast";
import starfishRoute from "@/router/starfish";
import "@/assets/svg-icon/iconfont.css";
import htmlToPdf from "@/utils/htmlToPdf";

import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "9TkIVLPsvOffykg7SZYioLSeSgTI86SZ"
});

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Toast);
Vue.prototype.$imgBaseUrl = "https://tm-files.oss-cn-beijing.aliyuncs.com/";
Vue.prototype.moment = dayjs;
Vue.prototype.common = Common;
Vue.prototype.lodash = lodash;
Vue.prototype.QrCode = QrCode;
Vue.use(htmlToPdf);
// Vue.prototype.pdfJs = pdfJs;
Vue.prototype.$bus = new Vue();

Vue.prototype.$imgBaseUrl = process.env.VUE_APP_BASE_FILE;
Vue.prototype.$sleep = time => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

const encryptPassword = function (password) {
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCz2R8NwOoFC1VrJmHL0rwnBrneaVIvd64HOxE3J4636iPvQDHwfJN5oHKLTf1XoSTK7p9ITVvPPaYozYvtbh/5oznVehFeEzsu0mclpiqcuEJRJ2rwXubHPebbv2//+Cfa+jXYTAy43rz7FWi+cYgVTKL9MjSQn9ngztDdJBryMQIDAQAB"
  );
  var data = encrypt.encrypt(password);
  return data;
};
import { isSaasMember } from "@/api/membership";
const checkIsSassMember = function () {
  if (!Common.getUserInfo().userId) {
    console.log("无登录信息");
    return { flag: false };
  } else {
    try {
      let res = isSaasMember({ agentIde: Common.getUserInfo().userId });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
};

import { isHhr } from "@/api/partnerInform";
const getPartnerStatus = async function () {
  let params = {
    userIde: Common.getUserInfo().userId,
    inputType: 1
  };
  if (params.userIde) {
    let status;
    if (store.state.partnerStatus) status = store.state.partnerStatus;
    else {
      let res = await isHhr(params);
      status = res.agent.status;
    }

    console.log("合伙人状态：", status);
    await store.dispatch("setPartnerStatus", status);
    // 0审核中,
    // 1通过,
    // 2审核不通过
    // 3变更待审核,
    // 5资料填写中(未填写完成状态)
    // 6审核不通过永久删除状态',
    switch (status) {
      case 1:
      case 3:
        //通过
        break;
      case 0:
        //审核中
        window.location.href = `/partner-inform/submitting`;
        break;
      case 2:
      case 5:
        window.location.href = "/identity-choose";
        break;
      default:
        break;
    }
  } else {
    //无登录信息
    window.location.href = `${process.env.VUE_APP_LOGIN_QDS}`;
  }
};

router.beforeEach(async (to, from, next) => {
  //不需要登录的页面
  const toNameList = [
    "partnerInform",
    "agreementFile",
    "partnerInformSubmitting",
    "identityChoose",
    "supportCenter",
    "composeExportView",
    "wx_shareBrandAssess",
    "wx_patentDetailInfo",
    "wx_riskReportInfo"
  ];
  if (toNameList.includes(to.name)) {
    next();
    return;
  }
  store.dispatch("setNowRoute", to.fullPath);
  window.document.title = "合伙人中心_" + (to.meta.title || "");

  if (
    Common.getCookie("QDS_COOKIE") &&
    Common.getCookie("QDS_LOGIN_INFO_OFFICE") &&
    Common.getCookie("QDS_AGENT_ORGAN_INFO")
  ) {
    //判断账号是否异常 异常跳转对应页面
    if (!["membershipCategories"].includes(to.name)) {
      //全部会员方案页面不用判读是否入驻
      await getPartnerStatus();
    }

    //判断是否海星会员
    let res = await checkIsSassMember();
    if (["membershipCenter", "partnerCenter2"].includes(to.name)) {
      //海星计划和会员中心增加页面权限
      if (res.flag) {
        sessionStorage.setItem("isVIP", res.flag);
        next();
      } else {
        next(from.fullPath);
      }
    } else {
      next();
    }
    // 海星计划跳转辨别测试代码
    if (
      [
        "customerClueNew2",
        "customerClueNew",
        "singleCustomerClue2",
        "singleCustomerClue",
        "sendReminderMessage2",
        "sendReminderMessage"
      ].includes(to.name)
    ) {
      //客户线索公用一个页面
      next({
        name: to.name.endsWith("2") ? to.name : to.name + "2",
        query: to.query || null
      });
    } else if (
      to.name &&
      starfishRoute[0].children.map(i => i.name).includes(to.name + 2) &&
      res.flag
    ) {
      next({ name: to.name + 2, query: to.query || null });
    } else if (
      to.name &&
      !res.flag &&
      starfishRoute[0].children.map(i => i.name).includes(to.name)
    ) {
      next({ name: to.name.slice(0, -1), query: to.query || null });
    } else {
      next();
    }

    //客户流失浮窗广告
    // 获取今天的日期
    if (to.name.includes("customerLoss")) {
      const today = new Date();
      today.setHours(24, 0, 0, 0); // 将时间设置为今天的午夜（24点）
      jsCookie.set("doNotShow", "1", { expires: today });
      Common.ipStatisticFn("客户流失-130120", "/customer-loss"); // 埋点;
    }
  } else {
    //无登录信息 本地服务注入信息
    if (
      location.hostname == "localhost" ||
      location.hostname.slice(0, 3) == "172"
    ) {
      let res = await getCookies({
        userPhone: "15811079252", //15667290318   7 9  15811079252 18103518662 15612345678 LXN@123456 Lxn123456
        userPwd: encryptPassword("123456"),
        drivetoken: "21342",
        ip: "",
        terminalType: 1,
        types: "1,2,3"
      });
      console.log(res);

      let QDS_LOGIN_INFO_OFFICE = {
        operatorId: "319390",
        operatorName: "测测",
        userId: res.user.fuid,
        userName: "nickname",
        userImg: res.user.avtar,
        agentOrWriter: 2
      };
      jsCookie.set(
        "QDS_LOGIN_INFO_OFFICE",
        JSON.stringify(QDS_LOGIN_INFO_OFFICE)
      );

      let QDS_AGENT_ORGAN_INFO = {
        agentIde: res.user.fuid,
        account: res.user.userPhone,
        agentName: res.user.agentName,
        agentOrganId: "",
        agentOrganName: "",
        agentOrganConName: ""
      };
      jsCookie.set(
        "QDS_AGENT_ORGAN_INFO",
        JSON.stringify(QDS_AGENT_ORGAN_INFO)
      );
      jsCookie.set("QDS_COOKIE", "79EC83E4-E005-0837-8B83-6B85E573BEA3");
      next();
    } else {
      //线上pre  pro无登录信息 登录页面
      jsCookie.remove("QDS_COOKIE", { path: "", domain: ".quandashi.com" });
      jsCookie.remove("QDS_LOGIN_INFO_OFFICE", {
        path: "",
        domain: ".quandashi.com"
      });
      jsCookie.remove("QDS_AGENT_ORGAN_INFO", {
        path: "",
        domain: ".quandashi.com"
      });
      //一个没有 三个都删除  encodeURIComponent(`${process.env.VUE_APP_HHR_QDS}`)
      return (window.location.href = `${process.env.VUE_APP_LOGIN_QDS}/?callback=${window.location.href}`);
    }
  }
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
