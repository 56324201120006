import { ipStatistic } from "@/api/membership";

export const Common = {
  getCookie(name) {
    // 获取cookie
    const cookieContent = "; " + document.cookie;
    const cookies = cookieContent.split(`; ${name}=`);
    return cookies.length - 1 ? cookies.pop().split(";").shift() : "";
  },

  setCookie(name, val, days) {
    // 设置cookie
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${val}${expires}; path=/`;
  },

  delCookie(name) {
    // 移除cookie
    this.setCookie(name, "", -1);
  },

  isNull(data) {
    if (typeof data == "object") {
      return Object.keys(data).length == 0;
    }
    return data == "" || data == undefined || data == null;
  },

  getUserInfo() {
    const userInfo = this.getCookie("QDS_LOGIN_INFO_OFFICE");
    return this.isNull(userInfo)
      ? ""
      : JSON.parse(decodeURIComponent(userInfo));
  },

  getAgentInfo() {
    const agentInfo = this.getCookie("QDS_AGENT_ORGAN_INFO");
    return this.isNull(agentInfo)
      ? ""
      : JSON.parse(decodeURIComponent(agentInfo));
  },

  dateFormat(fmt, date) {
    let ret;
    const opt = {
      "y+": date.getFullYear().toString(), // 年
      "M+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "h+": date.getHours().toString(), // 时
      "m+": date.getMinutes().toString(), // 分
      "s+": date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  },

  regMail: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  regTel:
    /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
  getIsRule() {
    const loginInfo = this.getCookie("QDS_LOGIN_INFO");
    return this.isNull(loginInfo)
      ? ""
      : JSON.parse(decodeURIComponent(loginInfo)).isRule;
  },

  getUAA_TOKEN() {
    const UAAInfo = this.getCookie("UAA_TOKEN");
    return this.isNull(UAAInfo)
      ? ""
      : JSON.parse(decodeURIComponent(UAAInfo)).tokenValue;
  },

  isWeixinBrowser() {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  },

  //项目内埋点统计点击次数功能
  ipStatisticFn(statInfo, path = window.location.pathname) {
    ipStatistic({
      userId: Common.getUserInfo().userId,
      projectCode: statInfo.split("-")[1],
      projectName: statInfo.split("-")[0],
      source: 4,
      requestUrl: path
    });
  },

  //人名币大写
  convertCurrency(money) {
    //汉字的数字
    var cnNums = new Array(
      "零",
      "壹",
      "贰",
      "叁",
      "肆",
      "伍",
      "陆",
      "柒",
      "捌",
      "玖"
    );
    //基本单位
    var cnIntRadice = new Array("", "拾", "佰", "仟");
    //对应整数部分扩展单位
    var cnIntUnits = new Array("", "万", "亿", "兆");
    //对应小数部分单位
    var cnDecUnits = new Array("角", "分", "毫", "厘");
    //整数金额时后面跟的字符
    var cnInteger = "整";
    //整型完以后的单位
    var cnIntLast = "元";
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = "";
    //分离金额后用的数组，预定义
    var parts;
    if (money == "") {
      return "";
    }
    money = parseFloat(money);
    if (money >= maxNum) {
      //超出最大处理数字
      return "";
    }
    if (money == 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger;
      return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf(".") == -1) {
      integerNum = money;
      decimalNum = "";
    } else {
      parts = money.split(".");
      integerNum = parts[0];
      decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      var zeroCount = 0;
      var IntLen = integerNum.length;
      for (var i = 0; i < IntLen; i++) {
        var n = integerNum.substr(i, 1);
        var p = IntLen - i - 1;
        var q = p / 4;
        var m = p % 4;
        if (n == "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0];
          }
          //归零
          zeroCount = 0;
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
        }
        if (m == 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q];
        }
      }
      chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != "") {
      var decLen = decimalNum.length;
      for (var i = 0; i < decLen; i++) {
        var n = decimalNum.substr(i, 1);
        if (n != "0") {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i];
        }
      }
    }
    if (chineseStr == "") {
      chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == "") {
      chineseStr += cnInteger;
    }
    return chineseStr;
  }
};
